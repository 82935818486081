export function getFirstLettersOfName(value, message) {
  let name = value.split(" ");
  let msg = message.split(":");
  let firstLetters = "";
  name.forEach((item) => {
    firstLetters += item[0].toUpperCase();
  });
  if (
    msg[0].includes("From") &&
    msg[0].includes(localStorage.getItem("userEmail"))
  ) {
  } else if (msg[0].includes("From")) {
    let newFirstLetter = msg[0].split(" ")[1][0].toUpperCase();
    firstLetters = newFirstLetter;
  }

  return firstLetters;
}

export function extractUsernameFromMessage(value) {
  let message = "";
  let newValue = value.split(":");
  if (newValue[0].includes("From")) {
    newValue.forEach((msg, i) => {
      if (i !== 0) {
        message += msg.replace("https", "https:");
      }
    });
  } else {
    message = value;
  }

  return message;
}

export function showUserName(value, userEmailData) {
  let userName = "";
  if (value.includes(localStorage.getItem("userEmail"))) {
    userName = localStorage.getItem("userName");
  } else if (value.split(":")[0].includes("From")) {
    let email = userEmailData.filter(
      (item) => item.email === value.split(":")[0].split(" ")[1]
    );
    if (email.length) {
      userName = userEmailData.filter(
        (item) => item.email === value.split(":")[0].split(" ")[1]
      )[0].username;
    } else {
      userName = value.split(":")[0].split(" ")[1];
    }
  } else {
    userName = "Incorpify Team";
  }

  return userName;
}

export function appendEmoji(value, emoji) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, "text/html");

  // Find all <p> tags
  const pTags = doc.querySelectorAll("p");

  // Append emoji to the last <p> tag
  if (pTags.length > 0) {
    let lastPTag = pTags[pTags.length - 1];
    if (lastPTag.innerHTML.trim() === "<br>") lastPTag.innerHTML = "";
    lastPTag.append(emoji);
  }

  // Convert the modified document back to a string
  const modifiedHtmlString = doc.body.innerHTML;
  return value === "" ? emoji : modifiedHtmlString;
}

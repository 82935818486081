export function SvgOne() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      style={{ maxWidth: "100%" }}
      height="100%"
    >
      <g style={{}} fill="currentColor">
        <g style={{}} fill="currentColor">
          <path
            fill="currentColor"
            d="M92.013,47.005L77.3,32.29V18.95c0-3.03-2.466-5.496-5.499-5.496h-5.636c-2.081,0-3.897,1.164-4.828,2.876    L50.791,5.784c-1.046-1.046-2.741-1.046-3.787,0L5.783,47.005c-0.766,0.766-0.994,1.919-0.578,2.918    c0.413,1.001,1.39,1.653,2.472,1.653h10.722V82.42c0,6.88,5.598,12.48,12.479,12.48h36.757c6.881,0,12.484-5.601,12.484-12.48    V51.576h10.001c1.084,0,2.059-0.652,2.477-1.653C93.009,48.924,92.777,47.771,92.013,47.005z M67.634,89.545H30.877    c-3.93,0-7.123-3.195-7.123-7.125V50.297c0.265-0.457,0.59-0.875,0.98-1.25h49.043c0.391,0.375,0.715,0.793,0.985,1.25V82.42    C74.763,86.35,71.564,89.545,67.634,89.545z M77.438,46.221c-0.011,0-0.027,0.009-0.039,0.009h-56.28    c-0.017,0-0.027-0.009-0.044-0.009h-6.933l34.756-34.757L61.453,24.02c0.771,0.768,1.927,0.996,2.917,0.581    c1.002-0.416,1.657-1.393,1.657-2.475l0.138-3.316l5.779,0.141v14.448c0,0.71,0.28,1.39,0.781,1.894l10.932,10.929H77.438z"
            style={{}}
          ></path>
        </g>
        <g style={{}} fill="currentColor">
          <path
            fill="currentColor"
            d="M55.255,55.727H43.262c-2.835,0-5.136,2.304-5.136,5.136v11.993c0,2.832,2.301,5.136,5.136,5.136h11.993    c2.829,0,5.136-2.304,5.136-5.136V60.862C60.391,58.03,58.084,55.727,55.255,55.727z M56.114,72.855    c0,0.477-0.386,0.861-0.859,0.861H43.262c-0.479,0-0.864-0.385-0.864-0.861V60.862c0-0.477,0.385-0.861,0.864-0.861h11.993    c0.474,0,0.859,0.385,0.859,0.861V72.855z"
            style={{}}
          ></path>
        </g>
      </g>
    </svg>
  );
}

export function LogOut() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      enable-background="new 0 0 512 512"
      //   xml:space="preserve"
      style={{ maxWidth: "100%" }}
      height="100%"
    >
      <g style={{}} fill="currentColor">
        <polygon
          points="366.863,323.883 389.49,346.51 480,256 389.49,165.49 366.862,188.118 418.745,240 192,240 192,272 418.745,272     "
          style={{}}
          fill="currentColor"
        ></polygon>
        <g style={{}} fill="currentColor">
          <path
            d="M391.491,391.766C355.229,428.029,307.018,448,255.736,448c-51.287,0-99.506-19.971-135.772-56.235    C83.697,355.501,64,307.285,64,256c0-51.281,19.697-99.495,55.965-135.761C156.232,83.973,204.45,64,255.736,64    c51.279,0,99.491,19.973,135.755,56.238c2.527,2.528,4.966,5.121,7.333,7.762h40.731c-40.474-58.028-107.709-96-183.819-96    C132.021,32,32,132.298,32,256c0,123.715,100.021,224,223.736,224c76.112,0,143.35-37.97,183.822-96h-40.73    C396.46,386.643,394.021,389.236,391.491,391.766z"
            style={{}}
            fill="currentColor"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      //   xml:space="preserve"
      style={{ maxWidth: "100%" }}
      height="100%"
    >
      <g style={{ fontWeight: "700" }} fill="currentColor">
        <path
          d="M66.695,90.33h-33.06C20.601,90.33,10,79.725,10,66.691V33.639C10,20.597,20.601,10,33.635,10h33.06   c13.034,0,23.639,10.597,23.639,23.639v33.053C90.334,79.725,79.729,90.33,66.695,90.33z M33.635,17.088   c-9.124,0-16.547,7.427-16.547,16.55v33.053c0,9.123,7.423,16.55,16.547,16.55h33.06c9.124,0,16.55-7.427,16.55-16.55V33.639   c0-9.124-7.426-16.55-16.55-16.55H33.635z M46.638,70.284V53.588H30.066v-6.977h16.571V30.039h7.061v16.572h16.574v6.977H53.698   v16.696H46.638z"
          style={{}}
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
}

export function Rocket() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      style={{ background: "new 0 0 100 100", maxWidth: "100%" }}
      //   xml:space="preserve"
      height="100px"
    >
      <path
        d="M77.9,31.9c6.3-6.6-3.8-16-9.8-9.7C61.8,28.5,71.8,38.3,77.9,31.9z"
        style={{}}
        fill="currentColor"
      ></path>
      <path
        d="M57.2,73.1c6.3-2.7,12.2-6.5,17.6-11.2C91.3,47,100.9,23.4,97,2.7C71.8-1.6,39.4,14.1,27.2,43.5  C38.5,49.3,51.3,61.8,57.2,73.1z M65.8,19.9c9.1-9.5,23.9,5,14.5,14.3C71.1,43.7,56.3,29.2,65.8,19.9z"
        style={{}}
        fill="currentColor"
      ></path>
      <path
        d="M21.8,49C21.8,49,21.8,49,21.8,49c2.3-7.6,6.2-15.9,11.7-22.9l-17.4-5.2c-1.5-0.4-3.1,0-4.2,1.1l-6.9,7  c-1.6,1.6-1.6,4.3,0,5.9l16.2,16C21.5,50.2,21.7,49.6,21.8,49z"
        style={{}}
        fill="currentColor"
      ></path>
      <path
        d="M50.1,79.1l16.2,15.9c1.6,1.6,4.3,1.6,5.9,0l7-7.1c1.1-1.1,1.5-2.6,1-4.1l-5.1-17.8C67.9,72.1,58.8,76.6,50.1,79.1z"
        style={{}}
        fill="currentColor"
      ></path>
      <path
        d="M20.7,61.5c-6.2,5.9-11.9,13-16.1,20C22.2,69.2,31.3,79,19.6,96.3c6.1-3.8,12.1-8.6,17.5-14.1v0c0.8-0.8,1.5-1.5,2.2-2.3  C34.8,73.4,27.9,66.4,20.7,61.5z"
        style={{}}
        fill="currentColor"
      ></path>
      <path
        d="M26.1,46.6C23.7,53.3,23.2,58,22.9,59c6.9,4.8,14,11.8,18.8,18.6c1.1-0.2,5.9-0.8,12.4-3.3C48.7,64.1,36.7,52.2,26.1,46.6z"
        style={{}}
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function LetsDoItIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      style={{ background: "new 0 0 100 100", maxWidth: "100%" }}
      //   xml:space="preserve"
      height="100%"
    >
      <g style={{}} fill="currentColor">
        <path
          d="M37.1,26.6c3.8-2.1,8.1-3.2,12.5-3.2c5.9,0,11.7,2,16.3,5.6l0.4,0.3L77.5,20l6.2,7.4l0.5-0.6c1.6-2.2,1.8-4.8,0.3-6.5   c-1.4-1.6-4.1-2-6.4-0.8l-0.5,0.2l-0.4-0.4c-1-0.9-2.2-1.9-3.7-2.9l-0.7-0.9C67.4,7.9,64.6,4.1,54.7,2.4c-0.8-0.2-1.7-0.2-2.7-0.2   c-9.1,0-18,7.5-22.6,11.9L29,14.3c-0.4,0.3-0.9,0.5-1.3,0.8l-0.6,0.4l9.6,11.4L37.1,26.6z M37.6,10.5c3.9-2.8,9.2-5.8,14.4-5.8   c0.8,0,1.5,0.1,2.2,0.2c7.3,1.3,10.2,3.7,13.6,8c-5.6-2.8-11.9-4.2-18.2-4.2C45.5,8.7,41.5,9.3,37.6,10.5z"
          style={{}}
          fill="currentColor"
        ></path>
        <path
          d="M98.2,51.7c-0.2-8.6-3.3-11.6-8.4-16.3c-0.9-0.8-1.8-1.7-2.8-2.6c-0.8-1.8-1.7-3.4-2.6-4.9L84,27.2l-11.4,9.6l0.2,0.5   c5,9.2,4,20.5-2.5,28.8l-0.3,0.4l9.6,11.4l0.5-0.5c1.5-1.7,3-3.6,4.2-5.6c6.4-4.6,13.7-11,13.8-19.4L98.2,51.7L98.2,51.7z    M95.7,52.3c0,4.4-2.7,9-8.3,13.8c3.8-8.8,4.4-18.7,1.7-27.9c4.4,4.1,6.5,6.5,6.6,13.8v0v0.1l0,0L95.7,52.3z"
          style={{}}
          fill="currentColor"
        ></path>
        <path
          d="M62.2,73c-3.8,2.1-8.1,3.2-12.5,3.2c-5.9,0-11.7-2-16.3-5.6L33,70.2l-10.8,9.1l-6-7.1l-0.5,0.6c-1.6,2.2-1.8,4.8-0.3,6.5   c1.4,1.6,4.1,2,6.4,0.8l0.2,0.2c0.4,0.3,0.7,0.6,1.1,0.9l0.3,0.3c5.4,7.6,13.7,16.5,23,16.6h0c9.8,0,12.5-2.7,18.4-8.5   c0.9-0.9,2-2,3.1-3.1c1.4-0.7,2.5-1.3,3.6-2l0.6-0.4l-9.6-11.4L62.2,73z M49.6,90.9c4.2,0,8.2-0.6,12.2-1.8   c-4.8,4.7-6.9,6.4-15.1,6.5l0,0l-0.2,0c-6,0-11.8-5-15.8-9.3C36.5,89.3,43,90.9,49.6,90.9z"
          style={{}}
          fill="currentColor"
        ></path>
        <path
          d="M19.5,21.6l7.5-6.4l-0.6-0.5c-2.1-1.6-4.8-1.7-6.5-0.3c-1.7,1.4-2,4-0.8,6.4L19.5,21.6z"
          style={{}}
          fill="currentColor"
        ></path>
        <path
          d="M80.5,77.9L73,84.3l0.6,0.5c1.1,0.8,2.4,1.3,3.7,1.3c1.1,0,2-0.3,2.8-1c1.7-1.4,2-4,0.8-6.4L80.5,77.9z"
          style={{}}
          fill="currentColor"
        ></path>
        <path
          d="M15.3,72.4l11.4-9.6l-0.2-0.5c-5-9.2-4-20.5,2.4-28.8l0.3-0.4l-9.6-11.4l-0.5,0.5c-0.7,0.8-1.5,1.7-2.2,2.7   c-1.2,0.9-2.4,1.7-3.4,2.5c-6.6,4.7-9.4,6.7-11,16.3c-0.2,1-0.3,2-0.3,3.1c0,8.9,6.2,18,11.4,23.2l0.4,0.4c0.3,0.5,0.5,0.9,0.8,1.4   L15.3,72.4z M4.8,46.8c0-0.9,0.1-1.8,0.2-2.7c1.5-8,3-9.6,8.2-13.4c-5.1,9.7-6,21-2.8,31.4C6.9,57,4.8,51.5,4.8,46.8z"
          style={{}}
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
}

export function BagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      //   xml:space="preserve"
      style={{ maxWidth: "100%", color: "#ffffff" }}
      height="60px"
    >
      <path
        fill="currentColor"
        d="M83.91,24.868H66.498v-3.949c0-1.695-1.108-3.469-2.635-4.214l-3.709-1.812  c-1.075-0.525-2.725-0.906-3.924-0.906H43.756c-1.197,0-2.846,0.382-3.921,0.908l-3.702,1.81c-1.526,0.745-2.631,2.52-2.631,4.214  v3.949H16.089c-2.248,0-4.077,1.829-4.077,4.077v25.688c0,1.423,0.72,2.69,1.821,3.449v22.463c0,2.351,1.836,4.331,4.18,4.51  c0.126,0.009,12.899,0.958,31.986,0.958c19.085,0,31.858-0.949,31.988-0.958c2.342-0.179,4.18-2.159,4.18-4.51V58.086  c1.101-0.763,1.822-2.03,1.822-3.453V28.945C87.987,26.697,86.155,24.868,83.91,24.868z M37.894,20.95  c0.023-0.09,0.122-0.25,0.194-0.308l3.678-1.798c0.466-0.228,1.47-0.462,1.992-0.462h12.477c0.521,0,1.525,0.232,1.99,0.462  l3.688,1.798c0.067,0.06,0.167,0.218,0.191,0.308v3.918H37.894V20.95z M30.682,29.637h38.636v25.099  c-3.797,0.127-8.164,0.234-12.933,0.29c-0.179-2.015-1.871-3.6-3.932-3.6h-4.907c-2.059,0-3.753,1.585-3.931,3.6  c-4.77-0.056-9.138-0.163-12.933-0.29V29.637z M52.699,55.376v4.148c0,0.13-0.117,0.247-0.246,0.247h-4.907  c-0.13,0-0.249-0.117-0.249-0.247v-4.148c0-0.131,0.116-0.246,0.249-0.246h4.907C52.586,55.13,52.699,55.243,52.699,55.376z   M16.78,54.097V29.637h9.266v24.928C21.309,54.371,18.011,54.174,16.78,54.097z M81.398,80.314C79.606,80.439,67.515,81.24,50,81.24  c-17.5,0-29.61-0.803-31.398-0.926V58.98c1.744,0.098,4.291,0.227,7.445,0.355v4.289v0.003c0,1.277,1.037,2.316,2.319,2.316  c1.277,0,2.316-1.039,2.316-2.316v-0.003v-4.118c3.803,0.125,8.171,0.234,12.929,0.292c0.141,2.049,1.85,3.674,3.935,3.674h4.907  c2.084,0,3.793-1.625,3.936-3.674c4.757-0.056,9.126-0.163,12.929-0.292v4.118v0.003c0,1.277,1.037,2.316,2.318,2.316  c1.279,0,2.316-1.039,2.316-2.316v-0.003v-4.289c3.155-0.125,5.702-0.258,7.446-0.355V80.314z M83.221,54.097  c-1.229,0.077-4.532,0.274-9.269,0.469V29.637h9.269V54.097z"
        style={{}}
      ></path>
    </svg>
  );
}

export function HelpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      enable-background="new 0 0 512 512"
      //   xml:space="preserve"
      style={{ maxWidth: "100%", color: "#ffffff" }}
      height="60px"
    >
      <g style={{}} fill="currentColor">
        <path
          d="M501.344,202.664H362.656v-160C362.656,36.773,357.891,32,352,32H10.664C4.773,32,0,36.773,0,42.664V320   c0,5.891,4.773,10.656,10.664,10.656h32v64l115.203-64h55.469v96c0,5.906,4.773,10.688,10.664,10.688h170.656L469.344,480v-42.656   h32c5.875,0,10.656-4.781,10.656-10.688v-213.32C512,207.445,507.219,202.664,501.344,202.664z M152.336,309.344l-4.828,2.672   L64,358.406v-27.75v-21.312H42.664H21.336V53.336h320.008v256.008H157.867H152.336z M490.656,416h-21.312H448v21.344v5.906   l-42.75-24.438L400.344,416h-5.688H234.664v-85.344H352c5.891,0,10.656-4.766,10.656-10.656v-96h128V416z"
          style={{}}
          fill="currentColor"
        ></path>
        <path
          d="M181.352,96c-16.711,0-29.984,5.07-39.453,15.055c-14.953,15.781-13.938,37.922-13.883,38.859l0.016-0.008   c0.297,5.625,4.938,10.094,10.633,10.094c5.891,0,10.672-4.773,10.672-10.664c0-0.172-0.023-0.344-0.031-0.516h0.016   c-0.008-0.141-0.484-14.188,8.164-23.195c5.281-5.5,13.312-8.289,23.867-8.289c12.68,0,21.219,3.078,26.117,9.43   c3.875,5.016,5.844,12.617,5.867,22.586c0.008,7.148-6.133,16.328-17.297,25.859c-9.727,8.305-19.727,13.828-19.812,13.875   l-5.555,3.031l-0.016,31.867h0.031v0.008c0,5.891,4.781,10.664,10.672,10.664s10.664-4.773,10.664-10.664   c0-0.211-0.016-0.414-0.031-0.625l0.008-18.82c13.031-8.094,42.711-29.375,42.664-55.234C234.602,114.93,215.672,96,181.352,96z"
          style={{}}
          fill="currentColor"
        ></path>
        <path
          d="M181.336,245.336c-5.891,0-10.672,4.773-10.672,10.664s4.781,10.664,10.672,10.664S192,261.891,192,256   S187.227,245.336,181.336,245.336z"
          style={{}}
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
}

export function HamBurger() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
    </svg>
  );
}

export function RightCircleArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      // xml:space="preserve"
      style={{ maxWidth: "100%" }}
      height="24px"
    >
      <g style={{}} fill="currentColor">
        <path
          d="M16,50.02C16.001,68.787,31.214,84,49.979,84c18.767,0,33.98-15.213,33.98-33.98c0-18.765-15.214-33.978-33.981-33.979   C31.213,16.041,16.001,31.255,16,50.02z M72.114,52.356c-0.033,0.044-0.065,0.088-0.102,0.13c-0.064,0.078-0.135,0.156-0.212,0.232   c-0.001,0.003-0.005,0.006-0.007,0.008L54.815,69.705c-1.494,1.494-3.914,1.494-5.408,0c-1.492-1.493-1.492-3.914,0-5.406   l10.457-10.455H30.866c-2.111,0-3.824-1.712-3.824-3.824c0-2.11,1.713-3.822,3.824-3.822h28.998L49.409,35.742   c-1.494-1.492-1.494-3.914,0-5.408c1.492-1.491,3.912-1.491,5.404,0l16.982,16.983c0.082,0.081,0.153,0.16,0.22,0.243   c0.03,0.033,0.058,0.069,0.085,0.104c0.021,0.027,0.045,0.058,0.062,0.087c0.472,0.635,0.752,1.416,0.754,2.269   c-0.002,0.854-0.283,1.638-0.755,2.274C72.147,52.315,72.13,52.335,72.114,52.356z"
          style={{}}
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
}

export function DownArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      // xml:space="preserve"
      style={{ maxWidth: "100%" }}
      height="28px"
    >
      <path
        fill="currentColor"
        d="M50.111,72.659c-1.061,0-2.078-0.422-2.829-1.172L17.275,41.474c-1.562-1.562-1.562-4.095,0.001-5.657  c1.563-1.562,4.096-1.562,5.657,0l27.178,27.185l27.178-27.185c1.562-1.562,4.095-1.562,5.657,0  c1.562,1.562,1.562,4.094,0.001,5.657L52.94,71.487C52.189,72.237,51.172,72.659,50.111,72.659z"
        style={{}}
      ></path>
    </svg>
  );
}

export function Home() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      // xml:space="preserve"
      style={{ maxWidth: "100%" }}
      height="24px"
    >
      <g style={{}} fill="currentColor">
        <g style={{}} fill="currentColor">
          <path
            fill="currentColor"
            d="M92.013,47.005L77.3,32.29V18.95c0-3.03-2.466-5.496-5.499-5.496h-5.636c-2.081,0-3.897,1.164-4.828,2.876    L50.791,5.784c-1.046-1.046-2.741-1.046-3.787,0L5.783,47.005c-0.766,0.766-0.994,1.919-0.578,2.918    c0.413,1.001,1.39,1.653,2.472,1.653h10.722V82.42c0,6.88,5.598,12.48,12.479,12.48h36.757c6.881,0,12.484-5.601,12.484-12.48    V51.576h10.001c1.084,0,2.059-0.652,2.477-1.653C93.009,48.924,92.777,47.771,92.013,47.005z M67.634,89.545H30.877    c-3.93,0-7.123-3.195-7.123-7.125V50.297c0.265-0.457,0.59-0.875,0.98-1.25h49.043c0.391,0.375,0.715,0.793,0.985,1.25V82.42    C74.763,86.35,71.564,89.545,67.634,89.545z M77.438,46.221c-0.011,0-0.027,0.009-0.039,0.009h-56.28    c-0.017,0-0.027-0.009-0.044-0.009h-6.933l34.756-34.757L61.453,24.02c0.771,0.768,1.927,0.996,2.917,0.581    c1.002-0.416,1.657-1.393,1.657-2.475l0.138-3.316l5.779,0.141v14.448c0,0.71,0.28,1.39,0.781,1.894l10.932,10.929H77.438z"
            style={{}}
          ></path>
        </g>
        <g style={{}} fill="currentColor">
          <path
            fill="currentColor"
            d="M55.255,55.727H43.262c-2.835,0-5.136,2.304-5.136,5.136v11.993c0,2.832,2.301,5.136,5.136,5.136h11.993    c2.829,0,5.136-2.304,5.136-5.136V60.862C60.391,58.03,58.084,55.727,55.255,55.727z M56.114,72.855    c0,0.477-0.386,0.861-0.859,0.861H43.262c-0.479,0-0.864-0.385-0.864-0.861V60.862c0-0.477,0.385-0.861,0.864-0.861h11.993    c0.474,0,0.859,0.385,0.859,0.861V72.855z"
            style={{}}
          ></path>
        </g>
      </g>
    </svg>
  );
}

export function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      // xml:space="preserve"
      style={{ maxWidth: "100%" }}
      className="back-btn"
    >
      <path
        d="M64.242,41.325l-29.616-0.002h-8.753l10.711-1.958l7.747-7.747c1.638-1.635,2.542-3.815,2.542-6.134  c0-2.318-0.903-4.497-2.542-6.137c-1.639-1.639-3.818-2.542-6.136-2.542s-4.496,0.903-6.136,2.542L7.542,43.865  c-0.394,0.394-0.756,0.836-1.077,1.314c-0.178,0.264-0.295,0.492-0.358,0.615l-0.004,0.008l-0.035,0.062  c-0.095,0.169-0.254,0.451-0.403,0.809c-0.14,0.334-0.217,0.615-0.259,0.767L5.4,47.46l-0.001,0.004l-0.013,0.039  c-0.054,0.163-0.143,0.435-0.215,0.784c-0.094,0.461-0.129,0.875-0.145,1.208C5.01,49.647,4.998,49.833,5,50.048  c0.002,0.189,0.015,0.35,0.029,0.482c0.019,0.353,0.054,0.739,0.142,1.176c0.071,0.35,0.161,0.627,0.215,0.793l0.011,0.034  l0.004,0.015c0.043,0.154,0.122,0.438,0.26,0.771c0.16,0.387,0.333,0.693,0.456,0.908c0.057,0.112,0.16,0.312,0.307,0.539  l0.015,0.022l0.015,0.022c0.329,0.494,0.701,0.946,1.106,1.345l24.497,24.5c1.643,1.64,3.822,2.542,6.137,2.542  c2.314,0,4.492-0.901,6.133-2.536c3.388-3.391,3.387-8.896,0.004-12.279l-9.702-9.703h0.011l-6.257-6.261l9.056,6.261h26.793  c7.395,0,13.411,6.015,13.411,13.407c0,4.786,3.893,8.679,8.677,8.679c4.785,0,8.679-3.892,8.679-8.677  C95,55.124,81.202,41.325,64.242,41.325z"
        style={{}}
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function Clock(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6.93652V12.9365L16 14.9365M22 12.9365C22 18.4594 17.5228 22.9365 12 22.9365C6.47715 22.9365 2 18.4594 2 12.9365C2 7.41368 6.47715 2.93652 12 2.93652C17.5228 2.93652 22 7.41368 22 12.9365Z"
        stroke="#6F7490"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
}

export function Notification() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="18"
      height="18"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="css-i6dzq1"
    >
      <path
        d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"
        stroke="#6F7490"
      ></path>
      <path d="M13.73 21a2 2 0 0 1-3.46 0" stroke="#6F7490"></path>
    </svg>
  );
}

export function MoreHorizontal() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="css-i6dzq1"
    >
      <circle cx="12" cy="12" r="1" stroke="#6F7490"></circle>
      <circle cx="19" cy="12" r="1" stroke="#6F7490"></circle>
      <circle cx="5" cy="12" r="1" stroke="#6F7490"></circle>
    </svg>
  );
}

export function ThumpsUp() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="16"
      height="16"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="css-i6dzq1"
    >
      <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
    </svg>
  );
}

export function Reply() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="16"
      height="16"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="css-i6dzq1"
    >
      <polyline points="9 14 4 9 9 4"></polyline>
      <path d="M20 20v-7a4 4 0 0 0-4-4H4"></path>
    </svg>
  );
}

export function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      style={{ background: "new 0 0 100 100", maxWidth: "100%" }}
      // xml:space="preserve"
      height="20px"
    >
      <path
        style={{}}
        d="M0,50c0,27.612,22.388,50,50.012,50C77.612,100,100,77.612,100,50c0-27.618-22.388-50-49.988-50  C22.388,0,0,22.382,0,50z M75,49.902H56.25V75h-12.5V49.902H25.098l24.914-24.908L75,49.902z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function Loading(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="50 21 88 97"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      {...props}
    >
      <defs>
        <g />
        <clipPath id="1281333fe0">
          <path
            d="M 46.308594 21.332031 L 132.25 21.332031 L 132.25 117.734375 L 46.308594 117.734375 Z M 46.308594 21.332031 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#1281333fe0)">
        <path
          fill="#ff6221"
          d="M 94.394531 117.585938 C 67.878906 117.585938 46.308594 96.015625 46.308594 69.496094 C 46.308594 42.984375 67.878906 21.410156 94.394531 21.410156 C 107.761719 21.410156 120.632812 27.042969 129.707031 36.859375 L 132.167969 39.519531 L 113.546875 56.738281 L 111.085938 54.078125 C 106.730469 49.367188 100.804688 46.773438 94.394531 46.773438 C 81.863281 46.773438 71.671875 56.96875 71.671875 69.496094 C 71.671875 76.277344 74.679688 82.65625 79.921875 87 L 75.296875 92.578125 C 68.386719 86.851562 64.425781 78.441406 64.425781 69.496094 C 64.425781 52.972656 77.871094 39.527344 94.394531 39.527344 C 101.625 39.527344 108.390625 42.03125 113.789062 46.640625 L 121.808594 39.226562 C 114.335938 32.472656 104.535156 28.660156 94.394531 28.660156 C 71.875 28.660156 53.554688 46.976562 53.554688 69.496094 C 53.554688 92.015625 71.875 110.339844 94.394531 110.339844 C 102.992188 110.339844 111.222656 107.695312 118.191406 102.695312 C 120.054688 101.355469 121.824219 99.84375 123.453125 98.199219 L 128.605469 103.289062 C 126.695312 105.226562 124.613281 107.007812 122.414062 108.582031 C 114.210938 114.472656 104.519531 117.585938 94.394531 117.585938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}

export function SmallHorizontalLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="440px"
      height="33"
      style={{ width: "12%" }}
    >
      <path
        d="M0 16.5 L440 16.5"
        style={{ fill: "none", stroke: "rgb(255, 98, 33)", strokeWidth: "3px" }}
      ></path>
    </svg>
  );
}

export function TelegramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      // xml:space="preserve"
      style={{ maxWidth: "100%" }}
      height="24px"
    >
      <path
        d="M95.9,16.6L55.3,83.4c-0.6,1.1-2,1.4-3.1,0.7l-11.2-7.6L29.7,84c-0.9,0.6-2.1,0-2.1-1.1V67.3l51.5-38.5L18.3,61L4.9,51.8  c-1.5-1-1.2-3.2,0.5-3.8l89.7-32.4C95.7,15.3,96.3,16,95.9,16.6z"
        style={{}}
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function IncorpifyTeam() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="50 21 88 97"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <g />
        <clipPath id="1281333fe0">
          <path
            d="M 46.308594 21.332031 L 132.25 21.332031 L 132.25 117.734375 L 46.308594 117.734375 Z M 46.308594 21.332031 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#1281333fe0)">
        <path
          fill="#ffffff"
          d="M 94.394531 117.585938 C 67.878906 117.585938 46.308594 96.015625 46.308594 69.496094 C 46.308594 42.984375 67.878906 21.410156 94.394531 21.410156 C 107.761719 21.410156 120.632812 27.042969 129.707031 36.859375 L 132.167969 39.519531 L 113.546875 56.738281 L 111.085938 54.078125 C 106.730469 49.367188 100.804688 46.773438 94.394531 46.773438 C 81.863281 46.773438 71.671875 56.96875 71.671875 69.496094 C 71.671875 76.277344 74.679688 82.65625 79.921875 87 L 75.296875 92.578125 C 68.386719 86.851562 64.425781 78.441406 64.425781 69.496094 C 64.425781 52.972656 77.871094 39.527344 94.394531 39.527344 C 101.625 39.527344 108.390625 42.03125 113.789062 46.640625 L 121.808594 39.226562 C 114.335938 32.472656 104.535156 28.660156 94.394531 28.660156 C 71.875 28.660156 53.554688 46.976562 53.554688 69.496094 C 53.554688 92.015625 71.875 110.339844 94.394531 110.339844 C 102.992188 110.339844 111.222656 107.695312 118.191406 102.695312 C 120.054688 101.355469 121.824219 99.84375 123.453125 98.199219 L 128.605469 103.289062 C 126.695312 105.226562 124.613281 107.007812 122.414062 108.582031 C 114.210938 114.472656 104.519531 117.585938 94.394531 117.585938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}

export function AddFiles() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 128.000000 128.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
        fill="#323338"
        stroke="none"
      >
        <path
          d="M952 1169 c-24 -5 -58 -17 -75 -28 -18 -11 -147 -136 -289 -278 -219
-221 -257 -263 -263 -296 -27 -145 112 -238 236 -158 24 16 108 94 187 173
120 123 142 149 142 175 0 31 -14 43 -48 43 -8 0 -90 -74 -182 -165 -114 -114
-173 -165 -188 -165 -28 0 -62 34 -62 62 0 15 70 93 233 256 277 280 292 292
360 292 44 0 54 -4 88 -38 43 -44 54 -76 44 -130 -6 -32 -49 -80 -323 -356
-336 -337 -371 -366 -458 -366 -52 0 -137 43 -170 86 -53 68 -63 156 -27 229
8 17 127 143 264 280 245 247 266 274 237 303 -30 30 -55 10 -315 -250 -237
-240 -262 -268 -278 -317 -39 -120 -2 -258 90 -339 81 -71 204 -93 317 -57 49
16 80 44 386 349 215 213 340 345 352 372 40 82 20 194 -46 260 -56 56 -130
78 -212 63z"
        />
      </g>
    </svg>
  );
}

export function Emoji() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="18"
      height="18"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="css-i6dzq1"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
      <line x1="9" y1="9" x2="9.01" y2="9"></line>
      <line x1="15" y1="9" x2="15.01" y2="9"></line>
    </svg>
  );
}

export function Calendar() {
  return (
    <svg
      viewBox="0 0 24 24"
      width="20"
      height="20"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="css-i6dzq1"
    >
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="16" y1="2" x2="16" y2="6"></line>
      <line x1="8" y1="2" x2="8" y2="6"></line>
      <line x1="3" y1="10" x2="21" y2="10"></line>
    </svg>
  );
}

export function AdobeAcrobat() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 46 61"
      width="45px"
      height="50px"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.46c0,.25-.1.49-.28.67l-14.52,14.62c-.18.18-.43.29-.68.29ZM1.93,59.08h28.02l13.96-14.06.16-43.11H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.62c-.18.19-.43.29-.69.29ZM37.62,46.38c-4.6,0-6.31,1.73-6.31,6.38v4.94l11.25-11.32h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M14.17,12.53h-1.67v-1.47h1.67v1.47ZM17.54,9.48v3.05h-1.66V4.14h3.33c.95,0,1.7.26,2.26.75.54.49.82,1.13.82,1.94s-.28,1.45-.82,1.94c-.56.47-1.31.71-2.26.71h-1.67ZM17.54,8.18h1.67c.47,0,.8-.11,1.05-.37.24-.25.35-.59.35-.98s-.11-.73-.35-1c-.22-.26-.58-.4-1.05-.4h-1.67v2.74ZM23.51,12.53V4.14h2.86c1.03,0,1.87.34,2.53.99.66.64.99,1.49.99,2.52v1.37c0,1.03-.34,1.88-.99,2.52-.66.66-1.5.98-2.53.98h-2.86ZM25.2,5.44v5.8h1.1c.6,0,1.07-.21,1.4-.6.34-.41.51-.94.51-1.6v-1.39c0-.66-.17-1.19-.51-1.58-.34-.41-.8-.62-1.4-.62h-1.1ZM36.37,9.05h-3.5v3.48h-1.67V4.14h5.75v1.3h-4.08v2.31h3.5v1.3Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.94,37.16c-.94,0-2.08.13-3.41.38-1.85-2-3.78-4.87-5.15-7.71,1.35-5.74.67-6.55.37-6.92-.32-.41-.77-1.07-1.27-1.07-.22,0-.8.09-1.03.17-.6.21-.9.66-1.16,1.24-.71,1.69.28,4.55,1.27,6.77-.86,3.46-2.3,7.58-3.84,10.95-3.84,1.77-5.88,3.5-6.05,5.15-.07.6.08,1.49,1.12,2.28.28.23.62.34.97.34.9,0,1.78-.68,2.82-2.15.75-1.07,1.57-2.52,2.41-4.33,2.71-1.19,6.06-2.28,8.95-2.88,1.59,1.54,3.03,2.33,4.27,2.33.9,0,1.68-.41,2.25-1.22.58-.81.71-1.56.39-2.18-.39-.77-1.35-1.15-2.94-1.15h0ZM12.81,47.02c-.47-.38-.45-.62-.43-.71.06-.56.94-1.56,3.11-2.79-1.65,3.05-2.53,3.44-2.68,3.5h0ZM21.12,23.67c.06-.02,1.07,1.13.11,3.27-1.46-1.49-.21-3.24-.11-3.27h0ZM19.04,39.98c1.01-2.45,1.98-5.17,2.7-7.7,1.14,2.05,2.51,4.04,3.87,5.64-2.15.51-4.45,1.24-6.57,2.05h0ZM32.1,39.51c-.32.43-.99.43-1.23.43-.54,0-.73-.32-1.55-.96.67-.07,1.31-.09,1.83-.09.9,0,1.05.13,1.18.19-.02.07-.08.21-.22.43h0Z"
      />
    </svg>
  );
}

export function Pngicon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-3"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.28-.69.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-3"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.73,2.54-8.28,8.25-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.6c-.19.19-.43.28-.69.28ZM37.63,46.4c-4.61,0-6.32,1.72-6.32,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-3"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.77"
        fill="#00b3e8"
      />
      <path
        class="cls-2"
        fill="#fefefe"
        d="M12.57,12.53h-1.67v-1.47h1.67v1.47ZM15.79,9.69v2.84h-1.57v-7.83h3.11c.9,0,1.59.23,2.11.7.51.45.77,1.05.77,1.81s-.26,1.35-.77,1.81c-.53.45-1.22.68-2.11.68h-1.53ZM15.79,8.49h1.53c.43,0,.77-.11.99-.36.22-.24.34-.55.34-.9,0-.38-.11-.7-.34-.94-.22-.24-.56-.36-.99-.36h-1.53v2.56ZM27.65,12.53h-1.55l-3.15-5.34h-.04v5.34h-1.57v-7.83h1.57l3.14,5.34h.04v-5.34h1.55v7.83ZM35.1,11.46c-.24.32-.6.58-1.08.83-.49.24-1.12.36-1.87.36-.93,0-1.72-.28-2.32-.88-.6-.6-.92-1.37-.92-2.31v-1.64c0-.94.3-1.71.88-2.31.58-.6,1.33-.9,2.24-.9s1.67.24,2.17.7c.51.47.77,1.07.77,1.84v.02h-1.48c-.02-.41-.15-.73-.39-.98-.24-.24-.58-.36-1.03-.36-.49,0-.86.19-1.16.55-.3.38-.43.85-.43,1.43v1.66c0,.58.15,1.07.45,1.43.3.38.71.56,1.22.56.38,0,.66-.04.88-.11.23-.08.39-.17.51-.28v-1.43h-1.38v-1.09h2.96v2.94Z"
      />
      <path
        class="cls-1"
        fill="#00b3e8"
        d="M11.52,19.34h3.27v3.29h3.27v-3.29h3.29v3.29h3.27v-3.29h3.27v3.29h3.3v-3.29h3.28v3.29h-3.28v3.29h3.28v3.31h-3.28v3.29h3.28v3.29h-3.28v3.31h3.28v3.29h-3.28v-3.29h-3.3v3.29h-3.27v-3.29h-3.27v3.29h-3.29v-3.29h-3.27v3.29h-3.27v-3.29h3.27v-3.31h-3.27v-3.29h3.27v-3.29h-3.27v-3.31h3.27v-3.29h-3.27v-3.29ZM24.64,22.64v3.29h3.27v-3.29h-3.27ZM27.91,25.93v3.31h3.3v-3.31h-3.3ZM18.07,22.64v3.29h3.29v-3.29h-3.29ZM21.36,25.93v3.31h3.27v-3.31h-3.27ZM24.64,29.24v3.29h3.27v-3.29h-3.27ZM27.91,32.53v3.29h3.3v-3.29h-3.3ZM21.36,39.14v-3.31h-3.29v3.31h3.29ZM18.07,35.83v-3.29h-3.27v3.29h3.27ZM27.91,39.14v-3.31h-3.27v3.31h3.27ZM24.64,35.83v-3.29h-3.27v3.29h3.27ZM21.36,32.53v-3.29h-3.29v3.29h3.29ZM18.07,29.24v-3.31h-3.27v3.31h3.27Z"
      />
    </svg>
  );
}

export function Jpgicon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.42.28.68l-.17,44.46c0,.25-.1.49-.28.67l-14.52,14.62c-.18.18-.43.28-.69.28ZM1.93,59.08h28.01l13.96-14.06.16-43.11H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.52,14.62c-.18.19-.43.28-.69.28ZM37.63,46.38c-4.6,0-6.32,1.73-6.32,6.38v4.95l11.25-11.32h-4.93Z"
      />
      <rect
        class="cls-2"
        x="1.06"
        y=".96"
        width="43.88"
        height="14.77"
        fill="#00b3e8"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M13.32,12.53h-1.67v-1.47h1.67v1.47ZM18.24,4.7h1.55v5.47c0,.75-.24,1.35-.73,1.81-.5.43-1.12.66-1.87.66-.8,0-1.46-.21-1.93-.6-.47-.4-.69-.98-.69-1.75l.02-.04h1.52c0,.41.09.71.3.9.19.19.47.28.79.28.3,0,.54-.11.75-.34.19-.23.3-.53.3-.92v-5.47ZM22.79,9.69v2.84h-1.57v-7.83h3.11c.9,0,1.61.22,2.12.68.52.47.77,1.07.77,1.83s-.24,1.35-.77,1.81c-.51.45-1.22.68-2.12.68h-1.53ZM22.79,8.49h1.53c.45,0,.77-.13.99-.36.22-.24.34-.55.34-.92s-.11-.68-.34-.92c-.22-.25-.54-.38-.99-.38h-1.53v2.58ZM34.35,11.46c-.24.32-.6.58-1.09.83-.51.24-1.12.36-1.87.36-.95,0-1.72-.3-2.32-.88-.62-.6-.92-1.37-.92-2.33v-1.62c0-.96.3-1.73.88-2.32.58-.6,1.33-.9,2.23-.9.95,0,1.68.22,2.19.7.5.47.77,1.07.77,1.83v.04h-1.48c-.04-.41-.17-.73-.39-.98-.24-.25-.58-.38-1.03-.38-.49,0-.86.19-1.16.56-.3.38-.43.85-.43,1.43v1.64c0,.6.15,1.07.45,1.45.3.38.71.56,1.22.56.37,0,.66-.04.88-.11.23-.08.39-.17.51-.28v-1.45h-1.39v-1.09h2.96v2.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M8.75,28.6c-2.5,0-4.54-2.05-4.54-4.57s2.04-4.55,4.54-4.55,4.56,2.04,4.56,4.55-2.04,4.57-4.56,4.57ZM8.75,21.39c-1.44,0-2.61,1.18-2.61,2.63s1.17,2.65,2.61,2.65,2.63-1.19,2.63-2.65-1.18-2.63-2.63-2.63Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.69,42.02H5.31c-.37,0-.71-.21-.87-.55-.16-.33-.11-.73.13-1.02l11.73-14.19c.33-.4.9-.47,1.32-.16l4.84,3.55,10.1-8.82c.19-.17.45-.26.71-.24.26.02.5.14.67.34l7.49,8.9c.14.17.22.39.22.62l.02,10.61c0,.26-.1.5-.28.68-.18.18-.43.28-.68.28ZM7.35,40.1h32.38l-.02-9.31-6.63-7.88-9.94,8.69c-.34.3-.84.32-1.21.05l-4.73-3.47-9.85,11.92Z"
      />
    </svg>
  );
}

export function Jpegicon() {
  return (
    <>
      <svg
        id="_1"
        data-name="1"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="45px"
        height="50px"
        viewBox="0 0 46 61"
      >
        <rect
          class="cls-4"
          fill="#fff"
          x="-608.77"
          y="-324.89"
          width="512"
          height="512"
        />
        <rect
          class="cls-3"
          fill="#00b3e8"
          x="-608.77"
          y="-324.89"
          width="147"
          height="512"
        />
        <g>
          <text
            class="cls-7"
            fill="#fff"
            transform="translate(-575.77 -149.98)"
          >
            <tspan x="0" y="0">
              FREE
            </tspan>
          </text>
          <text
            class="cls-7"
            fill="#fff"
            transform="translate(-575.77 -103.96)"
          >
            <tspan x="0" y="0">
              FILE{" "}
            </tspan>
          </text>
          <text class="cls-7" fill="#fff" transform="translate(-575.77 -57.95)">
            <tspan x="0" y="0">
              T
            </tspan>
          </text>
          <text class="cls-7" fill="#fff" transform="translate(-552.76 -57.95)">
            <tspan x="0" y="0">
              YPE
            </tspan>
          </text>
          <text class="cls-7" fill="#fff" transform="translate(-575.77 -11.93)">
            <tspan x="0" y="0">
              ICON
            </tspan>
          </text>
          <text class="cls-7" fill="#fff" transform="translate(-575.77 34.08)">
            <tspan x="0" y="0">
              S
            </tspan>
          </text>
          <text class="cls-7" fill="#fff" transform="translate(-552.72 34.08)">
            <tspan x="0" y="0">
              E
            </tspan>
          </text>
          <text class="cls-7" fill="#fff" transform="translate(-530.25 34.08)">
            <tspan x="0" y="0">
              T
            </tspan>
          </text>
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86,155.25h-29.34c-.53,0-.96-.43-.96-.96v-59.26c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.69.29ZM-181.24,153.32h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86,155.25c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.32,8.23-8.32h7.23c.39,0,.74.23.89.59s.07.77-.2,1.05l-14.5,14.65c-.19.19-.43.29-.69.29ZM-145.59,140.6c-4.6,0-6.31,1.73-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-182.11"
            y="95.03"
            width="43.83"
            height="14.8"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-169.01,106.64h-1.66v-1.49h1.66v1.49ZM-167.31,106.64v-8.42h2.86c1.03,0,1.85.34,2.52.98.65.66.99,1.51.99,2.53v1.4c0,1.04-.34,1.87-.99,2.53-.67.64-1.5.98-2.52.98h-2.86ZM-165.64,99.53v5.81h1.1c.62,0,1.08-.21,1.42-.62.34-.39.51-.92.51-1.58v-1.4c0-.66-.17-1.19-.51-1.61-.34-.4-.8-.6-1.42-.6h-1.1ZM-152.93,103.19c0,1.04-.32,1.89-.97,2.57-.64.66-1.48,1-2.5,1s-1.87-.34-2.5-1c-.64-.68-.97-1.53-.97-2.57v-1.51c0-1.04.32-1.89.97-2.55.64-.68,1.46-1.02,2.49-1.02s1.89.34,2.52,1.02c.65.66.97,1.51.97,2.55v1.51ZM-154.6,101.66c0-.66-.17-1.19-.49-1.62-.32-.41-.77-.62-1.34-.62s-1.01.21-1.33.62c-.3.42-.47.96-.47,1.62v1.53c0,.66.17,1.21.49,1.64.32.41.75.62,1.33.62s1.01-.21,1.33-.62c.32-.43.49-.98.49-1.64v-1.53ZM-145.35,103.77l.02.04c.02.91-.26,1.62-.82,2.15-.56.53-1.35.79-2.34.79s-1.83-.32-2.45-.98c-.62-.64-.93-1.47-.93-2.51v-1.66c0-1.02.3-1.85.91-2.51.62-.66,1.4-.98,2.37-.98,1.03,0,1.83.27,2.41.79.58.55.86,1.27.86,2.19l-.02.04h-1.63c0-.57-.13-.98-.39-1.28-.26-.28-.67-.43-1.23-.43-.5,0-.9.21-1.18.6-.3.41-.45.94-.45,1.57v1.68c0,.64.15,1.17.47,1.59.3.39.73.61,1.25.61s.9-.13,1.16-.42c.24-.28.37-.71.37-1.26h1.61Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-145.53,120.06h-29.34c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.34c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-145.53,126.86h-29.34c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.34c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-145.53,133.65h-29.34c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.34c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-160.2,140.43h-14.67c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h14.67c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77,155.27h-29.34c-.53,0-.96-.43-.96-.96v-59.28c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.67c-.18.18-.43.29-.68.29ZM-260.15,153.34h27.98l13.94-14.1.16-43.25h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77,155.27c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.3c0-5.76,2.54-8.33,8.23-8.33h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.67c-.18.19-.43.29-.68.29ZM-224.5,140.6c-4.6,0-6.31,1.73-6.31,6.4v4.96l11.23-11.36h-4.93Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-261.02"
            y="95.03"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-247.92,106.64h-1.66v-1.47h1.66v1.47ZM-244.55,103.58v3.06h-1.66v-8.42h3.33c.95,0,1.7.26,2.26.75.54.49.82,1.13.82,1.95s-.28,1.45-.82,1.94c-.56.47-1.31.72-2.26.72h-1.66ZM-244.55,102.28h1.66c.47,0,.8-.11,1.05-.38.24-.25.35-.59.35-.98s-.11-.74-.35-1c-.22-.27-.58-.4-1.05-.4h-1.66v2.75ZM-238.59,106.64v-8.42h2.86c1.03,0,1.87.34,2.52,1,.65.64.99,1.49.99,2.53v1.38c0,1.04-.34,1.89-.99,2.53-.65.66-1.5.98-2.52.98h-2.86ZM-236.91,99.53v5.81h1.1c.6,0,1.06-.21,1.4-.61.34-.41.5-.94.5-1.6v-1.4c0-.66-.17-1.19-.5-1.59-.34-.42-.8-.62-1.4-.62h-1.1ZM-225.75,103.15h-3.5v3.49h-1.66v-8.42h5.74v1.3h-4.08v2.32h3.5v1.3Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.17,131.35c-.93,0-2.07.13-3.4.38-1.85-2-3.78-4.89-5.14-7.74,1.35-5.76.67-6.57.37-6.95-.32-.41-.77-1.08-1.27-1.08-.22,0-.8.09-1.03.17-.6.21-.9.66-1.16,1.25-.71,1.7.28,4.57,1.27,6.8-.86,3.47-2.3,7.61-3.83,10.99-3.83,1.77-5.87,3.51-6.04,5.17-.07.6.08,1.49,1.12,2.28.28.23.62.34.97.34.9,0,1.78-.68,2.82-2.15.75-1.08,1.57-2.53,2.41-4.34,2.71-1.19,6.06-2.28,8.93-2.89,1.59,1.55,3.03,2.34,4.26,2.34.9,0,1.68-.42,2.24-1.23.58-.81.71-1.57.39-2.19-.39-.77-1.35-1.15-2.93-1.15h0ZM-249.28,141.24c-.47-.38-.45-.62-.43-.72.06-.57.94-1.57,3.1-2.79-1.64,3.06-2.52,3.45-2.67,3.51h0ZM-240.98,117.82c.06-.02,1.07,1.13.11,3.29-1.46-1.49-.21-3.25-.11-3.29h0ZM-243.06,134.18c1.01-2.45,1.98-5.19,2.69-7.72,1.14,2.06,2.5,4.06,3.87,5.66-2.15.51-4.45,1.25-6.56,2.06h0ZM-230.01,133.71c-.32.44-.99.44-1.23.44-.54,0-.73-.32-1.55-.96.67-.07,1.31-.09,1.83-.09.9,0,1.05.13,1.18.19-.02.07-.08.21-.22.43h0Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67,155.27h-29.34c-.53,0-.96-.43-.96-.96v-59.28c0-.53.43-.96.96-.96h44.01c.26,0,.5.1.68.28s.28.43.28.68l-.17,44.63c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-339.05,153.34h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67,155.27c-.12,0-.25-.03-.37-.07-.36-.15-.6-.5-.6-.89v-7.31c0-5.74,2.54-8.3,8.23-8.3h7.23c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-303.4,140.62c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.34h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-339.92"
            y="95.03"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-332.28,106.66h-1.66v-1.49h1.66v1.49ZM-324.36,106.66h-1.55v-3.27h-3.18v3.27h-1.55v-7.87h1.55v3.38h3.18v-3.38h1.55v7.87ZM-317.51,100.01h-2.21v6.65h-1.55v-6.65h-2.21v-1.22h5.96v1.22ZM-314.62,98.79l2,5.79h.02l2-5.79h2.04v7.87h-1.55v-2.36l.15-3.46-.04-.02-2.07,5.83h-1.05l-2.07-5.82-.04.02.15,3.44v2.36h-1.57v-7.87h2.04ZM-305.53,105.43h3.46v1.23h-5.03v-7.87h1.57v6.64Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-323.85,135.18c-.32,0-.63-.12-.88-.35l-7.28-6.9s-.02-.02-.03-.03c-.11-.11-.19-.23-.26-.36-.08-.18-.12-.37-.12-.55,0-.19.04-.37.12-.54.06-.13.15-.26.26-.36,0,0,.02-.02.03-.03l7.28-6.92c.51-.49,1.33-.47,1.82.05.49.51.47,1.33-.05,1.81l-6.31,5.99,6.31,5.98c.52.49.54,1.3.05,1.81-.25.27-.59.4-.93.4ZM-312.19,135.18c-.34,0-.68-.13-.93-.4-.49-.52-.46-1.33.05-1.81l6.32-5.98-6.32-5.99c-.52-.49-.54-1.3-.05-1.81.49-.52,1.3-.54,1.81-.05l7.3,6.92s.02.02.03.04c.1.11.19.22.25.35h0c.08.18.12.37.12.56s-.04.38-.13.55c-.06.13-.14.25-.25.35-.01,0-.02.02-.04.03l-7.3,6.9c-.25.23-.56.35-.88.35Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58,155.25h-29.34c-.53,0-.96-.43-.96-.96v-59.26c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-417.96,153.32h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58,155.25c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.33,8.23-8.33h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-382.31,140.6c-4.6,0-6.31,1.73-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-418.83"
            y="95.03"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-407.47,106.64h-1.68v-1.47h1.68v1.47ZM-399.49,103.77v.04c.02.91-.26,1.62-.82,2.15-.56.53-1.35.79-2.34.79s-1.81-.32-2.45-.98c-.62-.64-.93-1.47-.93-2.51v-1.66c0-1.02.3-1.85.92-2.51.62-.66,1.4-.98,2.37-.98,1.03,0,1.83.27,2.41.79.58.55.88,1.27.86,2.19l-.02.04h-1.63c0-.57-.13-.98-.39-1.28-.26-.28-.67-.43-1.23-.43-.49,0-.9.21-1.18.6-.3.41-.45.94-.45,1.57v1.68c0,.64.15,1.17.47,1.59.3.39.73.61,1.25.61s.92-.13,1.16-.42c.24-.28.37-.71.37-1.26h1.63ZM-393.81,104.45c0-.34-.11-.59-.34-.79-.22-.19-.62-.38-1.2-.55-.99-.28-1.74-.62-2.24-1.02-.51-.39-.77-.94-.77-1.64s.3-1.25.88-1.68c.58-.44,1.33-.66,2.22-.66s1.66.25,2.24.74c.58.49.86,1.1.84,1.81l-.02.04h-1.63c0-.38-.13-.7-.37-.93-.26-.24-.62-.36-1.08-.36s-.8.09-1.05.3c-.24.19-.36.45-.36.76,0,.28.13.51.39.7.26.19.71.38,1.35.57.91.26,1.61.6,2.09,1.04.47.42.71.96.71,1.66s-.28,1.29-.84,1.7c-.58.42-1.31.62-2.24.62s-1.7-.23-2.37-.7c-.67-.47-.99-1.13-.97-1.98v-.04h1.63c0,.49.15.87.47,1.1.3.23.71.34,1.25.34.47,0,.8-.09,1.05-.26.24-.21.35-.46.35-.76h0ZM-386.37,104.45c0-.34-.11-.59-.34-.79-.22-.19-.64-.38-1.2-.55-.99-.28-1.74-.62-2.24-1.02-.51-.39-.77-.94-.77-1.64s.3-1.25.88-1.68c.58-.44,1.31-.66,2.22-.66s1.66.25,2.22.74c.58.49.86,1.1.84,1.81v.04h-1.63c0-.38-.13-.7-.39-.93-.24-.24-.62-.36-1.08-.36s-.78.09-1.03.3c-.24.19-.37.45-.37.76,0,.28.13.51.39.7.26.19.71.38,1.36.57.92.26,1.61.6,2.07,1.04.49.42.71.96.71,1.66s-.28,1.29-.84,1.7c-.56.42-1.31.62-2.22.62s-1.7-.23-2.37-.7c-.67-.47-1.01-1.13-.99-1.98l.02-.04h1.63c0,.49.15.87.45,1.1.3.23.73.34,1.27.34.45,0,.8-.09,1.05-.26.24-.21.35-.46.35-.76h0Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-391.84,135.97c-.53,0-.96-.43-.96-.96s.43-.96.96-.96c.7,0,1.36,0,1.59-.23.12-.12.31-.46.3-1.48l-.06-2.87c-.08-2.15.66-3.38,1.28-4.03.04-.04.07-.07.11-.11-.04-.03-.07-.07-.11-.11-.63-.65-1.36-1.88-1.28-4.04l.06-2.89c0-.99-.19-1.34-.3-1.45-.23-.23-.9-.23-1.6-.23-.53,0-.96-.43-.96-.96s.43-.96.96-.96c1.07,0,2.17,0,2.96.8.6.6.87,1.5.86,2.83l-.04,2.18s0,0,0,.01l-.02.73c-.04,1.17.22,2.11.75,2.64.31.32.69.48,1.13.48.53,0,.96.43.96.96s-.43.96-.96.96c-.44,0-.82.16-1.13.48-.52.54-.79,1.47-.75,2.64l.02.74s0,0,0,0h0l.04,2.15c0,1.35-.26,2.26-.86,2.86-.8.8-1.9.8-2.96.8ZM-402.01,135.97c-1.06,0-2.16,0-2.96-.8-.6-.61-.87-1.51-.85-2.85l.02-2.14s0-.02,0-.03c0,0,0-.02,0-.03l.04-.73c.04-1.14-.22-2.08-.74-2.62-.31-.32-.7-.49-1.15-.49-.53,0-.96-.43-.96-.96s.43-.96.96-.96c.45,0,.84-.16,1.15-.49.52-.54.78-1.47.74-2.63l-.04-.71s0-.02,0-.03,0-.02,0-.03l-.02-2.16c-.02-1.32.25-2.22.84-2.83.8-.8,1.9-.8,2.96-.8.53,0,.96.43.96.96s-.43.96-.96.96c-.7,0-1.36,0-1.59.23-.11.12-.3.46-.29,1.45l.06,2.87c.08,2.17-.66,3.4-1.28,4.05-.04.04-.08.08-.12.11.04.04.08.08.12.12.63.65,1.36,1.88,1.28,4.04l-.04.73-.02,2.13c-.01,1.01.18,1.36.29,1.47.23.23.89.23,1.58.23.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86,61.1h-29.34c-.53,0-.96-.43-.96-.96V.86c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.63c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.28-.69.28ZM-181.24,59.18h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86,61.1c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.31c0-5.74,2.54-8.3,8.23-8.3h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.19.19-.43.28-.69.28ZM-145.59,46.45c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-182.11"
            y=".86"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-164.84,12.47h-1.68v-1.47h1.68v1.47ZM-158.69,10.66h-2.92l-.56,1.81h-1.7l2.88-8.4h1.7l2.86,8.4h-1.68l-.58-1.81ZM-161.19,9.38h2.09l-1.03-3.34h-.04l-1.03,3.34ZM-153.89,12.47h-1.66V4.07h1.66v8.4Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-144.24,29.63v-5.7h-5.63v1.91h-5.62v-3.79h-9.4v3.79h-5.62v-1.91h-5.64v5.7h5.64v-1.91h3.33c-4.3,2.51-7.08,7.2-7.08,12.33,0,.53.41.96.93.96s.93-.43.93-.96c0-4.96,3.03-9.48,7.51-11.37v2.83h9.4v-2.83c4.47,1.89,7.51,6.4,7.51,11.37,0,.53.41.96.93.96s.93-.43.93-.96c0-5.13-2.8-9.82-7.08-12.33h3.33v1.91h5.63ZM-172.41,27.72h-1.87v-1.89h1.87v1.89ZM-157.38,29.63h-5.63v-5.7h5.63v5.7ZM-147.98,25.84h1.87v1.89h-1.87v-1.89Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77,61.1h-29.34c-.53,0-.96-.43-.96-.96V.86c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.62c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-260.15,59.18h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77,61.1c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.3c0-5.74,2.54-8.3,8.23-8.3h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-224.5,46.45c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-261.02"
            y=".86"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-248.42,12.49h-1.66v-1.49h1.66v1.49ZM-244.03,7.41l1.46-2.79h1.81l-2.3,3.91,2.43,3.96h-1.89l-1.5-2.83-1.49,2.83h-1.83l2.37-3.96-2.32-3.91h1.81l1.44,2.79ZM-238.25,11.26h3.46v1.23h-5.01v-7.87h1.55v6.64ZM-229.69,10.43c0-.3-.11-.55-.32-.72-.2-.19-.58-.36-1.1-.53-.94-.26-1.65-.59-2.11-.96-.47-.36-.71-.87-.71-1.51s.28-1.17.82-1.59c.54-.41,1.23-.6,2.08-.6s1.55.23,2.09.68c.54.45.8,1.02.79,1.7l-.02.04h-1.51c0-.36-.11-.66-.35-.87-.24-.23-.58-.34-1.01-.34s-.75.1-.97.28c-.22.19-.34.42-.34.7,0,.26.11.49.35.66.24.17.67.34,1.27.53.86.25,1.51.57,1.94.96.45.4.67.9.67,1.55,0,.68-.26,1.21-.8,1.61-.52.38-1.22.58-2.07.58s-1.59-.23-2.22-.68c-.62-.43-.93-1.05-.92-1.85l.02-.02h1.51c0,.46.15.81.43,1.02.28.22.67.32,1.18.32.43,0,.75-.08.97-.27.22-.17.34-.4.34-.7h0Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-221.32,28.49h-35.59c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.59c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM-256.1,26.88h33.98v-4h-33.98v4Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-221.32,34.12h-35.59c-.44,0-.8-.36-.8-.8v-5.63c0-.44.36-.8.8-.8h35.59c.44,0,.8.36.8.8v5.63c0,.44-.36.8-.8.8ZM-256.1,32.51h33.98v-4.02h-33.98v4.02Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-221.32,39.74h-35.59c-.44,0-.8-.36-.8-.8v-5.62c0-.44.36-.8.8-.8h35.59c.44,0,.8.36.8.8v5.62c0,.44-.36.8-.8.8ZM-256.1,38.14h33.98v-4.02h-33.98v4.02Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-232.27,39.74h-13.68c-.44,0-.8-.36-.8-.8v-16.86c0-.44.36-.8.8-.8h13.68c.44,0,.8.36.8.8v16.86c0,.44-.36.8-.8.8ZM-245.15,38.14h12.08v-15.25h-12.08v15.25Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67,61.1h-29.34c-.53,0-.96-.43-.96-.96V.86c0-.53.43-.96.96-.96h44.01c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.5-.28.67l-14.5,14.67c-.18.18-.43.29-.68.29ZM-339.05,59.18h27.98l13.95-14.1.16-43.25h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67,61.1c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.3c0-5.76,2.54-8.32,8.23-8.32h7.23c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.5,14.67c-.18.19-.43.29-.68.29ZM-303.4,46.43c-4.6,0-6.31,1.73-6.31,6.4v4.96l11.23-11.36h-4.93Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-339.92"
            y=".86"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-327.68,12.47h-1.66v-1.47h1.66v1.47ZM-322.77,4.62h1.55v5.49c0,.75-.24,1.36-.73,1.81-.5.43-1.12.66-1.87.66-.8,0-1.46-.21-1.93-.6-.47-.4-.69-.98-.69-1.75l.02-.04h1.51c0,.42.09.72.3.91.19.19.47.28.79.28.3,0,.54-.11.75-.34.19-.23.3-.53.3-.93v-5.49ZM-318.22,9.62v2.85h-1.57v-7.85h3.1c.9,0,1.61.22,2.11.68.52.47.77,1.08.77,1.83s-.24,1.36-.77,1.81c-.5.45-1.21.68-2.11.68h-1.53ZM-318.22,8.41h1.53c.45,0,.77-.13.99-.36.22-.24.34-.55.34-.93s-.11-.68-.34-.92c-.22-.25-.54-.38-.99-.38h-1.53v2.59ZM-306.67,11.4c-.24.32-.6.58-1.08.83-.5.24-1.12.36-1.87.36-.95,0-1.72-.3-2.32-.89-.62-.6-.92-1.38-.92-2.34v-1.62c0-.96.3-1.74.88-2.32.58-.6,1.33-.9,2.22-.9.95,0,1.68.22,2.19.7.5.47.77,1.08.77,1.83v.04h-1.48c-.04-.42-.17-.74-.39-.98-.24-.25-.58-.38-1.03-.38-.49,0-.86.19-1.16.56-.3.38-.43.85-.43,1.43v1.64c0,.6.15,1.08.45,1.45.3.38.71.57,1.21.57.37,0,.65-.04.88-.11.23-.08.39-.17.51-.28v-1.45h-1.38v-1.1h2.95v2.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-332.24,28.59c-2.5,0-4.53-2.06-4.53-4.59s2.03-4.57,4.53-4.57,4.55,2.05,4.55,4.57-2.04,4.59-4.55,4.59ZM-332.24,21.36c-1.44,0-2.61,1.19-2.61,2.64s1.17,2.66,2.61,2.66,2.63-1.2,2.63-2.66-1.18-2.64-2.63-2.64Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-300.34,42.05h-35.34c-.37,0-.71-.21-.87-.55-.16-.34-.11-.73.13-1.02l11.72-14.23c.33-.4.9-.47,1.31-.16l4.83,3.56,10.09-8.85c.19-.17.45-.26.71-.24.26.02.5.14.66.34l7.48,8.93c.14.17.22.39.22.62l.02,10.65c0,.26-.1.5-.28.68-.18.18-.42.28-.68.28ZM-333.64,40.13h32.34l-.02-9.34-6.62-7.91-9.93,8.71c-.34.3-.84.32-1.21.05l-4.72-3.48-9.84,11.96Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M30.35,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.42.28.68l-.17,44.46c0,.25-.1.49-.28.67l-14.52,14.62c-.18.18-.43.28-.69.28ZM1.93,59.08h28.01l13.96-14.06.16-43.11H1.93v57.16Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.52,14.62c-.18.19-.43.28-.69.28ZM37.62,46.38c-4.6,0-6.32,1.73-6.32,6.38v4.95l11.25-11.32h-4.93Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="1.06"
            y=".96"
            width="43.88"
            height="14.77"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M8.74,28.6c-2.5,0-4.54-2.05-4.54-4.57s2.04-4.55,4.54-4.55,4.56,2.04,4.56,4.55-2.04,4.57-4.56,4.57ZM8.74,21.39c-1.44,0-2.61,1.18-2.61,2.63s1.17,2.65,2.61,2.65,2.63-1.19,2.63-2.65-1.18-2.63-2.63-2.63Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M40.68,42.02H5.3c-.37,0-.71-.21-.87-.55-.16-.33-.11-.73.13-1.02l11.73-14.19c.33-.4.9-.47,1.32-.16l4.84,3.55,10.1-8.82c.19-.17.45-.26.71-.24.26.02.5.14.67.34l7.49,8.9c.14.17.22.39.22.62l.02,10.61c0,.26-.1.5-.28.68-.18.18-.43.28-.68.28ZM7.34,40.1h32.38l-.02-9.31-6.63-7.88-9.94,8.69c-.34.3-.84.32-1.21.05l-4.73-3.47-9.85,11.92Z"
          />
          <text class="cls-5" fill="#fefefe" transform="translate(6.78 12.36)">
            <tspan x="0" y="0">
              .JPEG
            </tspan>
          </text>
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58,61.1h-29.34c-.53,0-.96-.43-.96-.96V.86c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.62c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-417.96,59.18h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58,61.1c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.3c0-5.74,2.54-8.3,8.23-8.3h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-382.31,46.45c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-418.83"
            y=".86"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-406.72,12.47h-1.66v-1.47h1.66v1.47ZM-403.53,9.62v2.85h-1.55v-7.85h3.1c.88,0,1.59.22,2.11.7.5.45.77,1.06.77,1.81s-.26,1.36-.77,1.81c-.52.45-1.23.68-2.11.68h-1.55ZM-403.53,8.41h1.55c.43,0,.75-.11.97-.36.22-.24.34-.55.34-.91,0-.38-.11-.7-.32-.94-.22-.25-.56-.36-.99-.36h-1.55v2.57ZM-396.39,9.62v2.85h-1.57v-7.85h3.1c.9,0,1.59.22,2.11.7.5.45.77,1.06.77,1.81s-.26,1.36-.77,1.81c-.52.45-1.22.68-2.11.68h-1.53ZM-396.39,8.41h1.53c.43,0,.77-.11.99-.36.22-.24.34-.55.34-.91,0-.38-.11-.7-.34-.94-.22-.25-.56-.36-.99-.36h-1.53v2.57ZM-385.45,5.85h-2.21v6.62h-1.55v-6.62h-2.19v-1.23h5.94v1.23Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.47,45.9c-.34,0-.66-.18-.84-.49l-3.35-5.97c-.26-.46-.09-1.05.37-1.31.46-.26,1.05-.1,1.31.37l3.35,5.97c.26.46.1,1.05-.37,1.31-.15.08-.31.12-.47.12ZM-404.37,45.9c-.16,0-.32-.04-.47-.12-.46-.26-.63-.85-.37-1.31l3.35-5.97c.26-.46.85-.63,1.31-.37.46.26.63.85.37,1.31l-3.35,5.97c-.18.31-.5.49-.84.49Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-396.91,22.99c-.53,0-.96-.43-.96-.96v-2.06c0-.53.43-.96.96-.96s.96.43.96.96v2.06c0,.53-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-381.38,39.94h-31.08c-.53,0-.96-.43-.96-.96v-16.95c0-.53.43-.96.96-.96h31.08c.53,0,.96.43.96.96v16.95c0,.53-.43.96-.96.96ZM-411.5,38.01h29.16v-15.03h-29.16v15.03Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-385.45,35.63c-.53,0-.96-.43-.96-.96v-8.21c0-.53.43-.96.96-.96s.96.43.96.96v8.21c0,.53-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-388.54,35.63c-.53,0-.96-.43-.96-.96l-.02-4.81c0-.53.43-.96.96-.97h0c.53,0,.96.43.96.96l.02,4.81c0,.53-.43.96-.96.97h0Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-391.64,35.63c-.53,0-.96-.43-.96-.96v-2.42c0-.53.43-.96.96-.96s.96.43.96.96v2.42c0,.53-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-398.98,27.42h-9.46c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h9.46c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-402.67,31.54h-5.77c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h5.77c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-396.76,35.63h-11.68c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h11.68c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86-33.09h-29.34c-.53,0-.96-.43-.96-.96v-59.25c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.28-.69.28ZM-181.24-35.01h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86-33.09c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.33,8.23-8.33h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.19.19-.43.28-.69.28ZM-145.59-47.74c-4.6,0-6.31,1.74-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-182.11"
            y="-93.31"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-171.17-81.98h-1.55v-1.38h1.55v1.38ZM-167.55-89.83l1.98,5.8h.02l2-5.8h2.04v7.85h-1.55v-2.34l.15-3.47h-.04l-2.08,5.81h-1.05l-2.07-5.8h-.04l.15,3.45v2.34h-1.55v-7.85h2.04ZM-158.48-84.83v2.85h-1.57v-7.85h3.1c.9,0,1.61.23,2.11.7.52.45.77,1.06.77,1.81s-.24,1.36-.77,1.81c-.5.45-1.22.68-2.11.68h-1.53ZM-158.48-86.04h1.53c.45,0,.77-.13.99-.36.22-.25.34-.55.34-.93s-.11-.68-.34-.92c-.22-.25-.54-.38-.99-.38h-1.53v2.59ZM-148.54-84.89h.88v1.21h-.88v1.7h-1.55v-1.7h-3.18l-.07-.94,3.23-5.21h1.57v4.95ZM-151.89-84.89h1.79v-2.85h-.04l-.13.23-1.62,2.62Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-160.2-47.63c-7.84,0-14.22-6.44-14.22-14.36s6.38-14.36,14.22-14.36,14.24,6.44,14.24,14.36-6.39,14.36-14.24,14.36ZM-160.2-74.74c-6.96,0-12.62,5.72-12.62,12.75s5.66,12.75,12.62,12.75,12.64-5.72,12.64-12.75-5.67-12.75-12.64-12.75Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-163.79-54.17c-.6,0-1.16-.32-1.46-.83-.16-.31-.22-.58-.22-.86v-12.27c0-.93.75-1.69,1.66-1.69.33,0,.65.1.92.28l5.2,3.02s.08.05.12.09l5.13,2.99c.8.48,1.06,1.5.6,2.29-.14.25-.35.46-.61.61l-5.15,3s-.06.05-.1.07l-5.27,3.08c-.27.14-.55.22-.83.22ZM-163.79-68.21s-.08.03-.08.09v12.27s0,.06.03.1c0-.02.03-.02.05-.02.01,0,.03,0,.04,0l5.13-3s.06-.05.1-.07l5.27-3.08c.05-.06.03-.11,0-.13l-5.21-3.04s-.08-.05-.12-.09l-5.15-2.99s-.04-.03-.07-.04Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77-33.07h-29.34c-.53,0-.96-.43-.96-.96v-59.28c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.63c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-260.15-34.99h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77-33.07c-.12,0-.25-.03-.37-.07-.36-.15-.6-.5-.6-.89v-7.31c0-5.74,2.54-8.3,8.23-8.3h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-224.5-47.72c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-261.02"
            y="-93.31"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-248.87-46.09c-2.98,0-5.41-2.23-5.41-4.96s2.43-4.95,5.41-4.95c.91,0,1.78.2,2.55.58v-15.23c0-.71.52-1.31,1.23-1.42l14.55-1.89c.05,0,.12-.01.19-.01.35,0,.69.13.94.37.3.27.48.66.48,1.07v17.8c0,2.73-2.43,4.95-5.41,4.95s-5.41-2.22-5.41-4.95c0-2.89,2.3-4.9,5.6-4.9,1.19,0,2.33.27,3.29.75v-7.17l-13.53,1.77v10.8c0,.24.02.48.04.73.03.43.06.92.04,1.55-.06,3.15-1.8,5.12-4.55,5.12ZM-248.87-54.08c-1.92,0-3.49,1.36-3.49,3.02s1.56,3.04,3.49,3.04c2.12,0,2.6-1.77,2.63-3.25.02-.54,0-.96-.04-1.37-.01-.16-.02-.32-.03-.47-.65-.62-1.56-.98-2.56-.98ZM-234.15-57.72c-1.7,0-3.68.78-3.68,2.98,0,1.67,1.56,3.02,3.48,3.02s3.49-1.36,3.49-3.02v-1.84c-.76-.71-1.99-1.14-3.29-1.14ZM-244.39-70.23v3.99l13.53-1.77v-3.98l-13.53,1.76Z"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-249.97-82h-1.55v-1.38h1.55v1.38ZM-246.34-89.79l1.98,5.74h.02l2-5.74h2.06v7.8h-1.57v-2.32l.15-3.45h-.04l-2.07,5.78h-1.05l-2.07-5.76h-.04l.15,3.44v2.32h-1.55v-7.8h2.04ZM-237.28-84.83v2.83h-1.55v-7.8h3.08c.9,0,1.61.23,2.11.68.52.45.79,1.06.79,1.81s-.26,1.34-.79,1.79c-.51.45-1.22.68-2.11.68h-1.53ZM-237.28-86.04h1.53c.45,0,.77-.11.99-.36.22-.23.34-.53.34-.91s-.11-.68-.34-.92c-.21-.25-.54-.36-.99-.36h-1.53v2.55ZM-230.33-86.57h.88c.35,0,.64-.09.82-.28.17-.21.26-.47.26-.81,0-.32-.09-.57-.26-.75-.19-.19-.45-.28-.79-.28-.28,0-.54.08-.73.25-.21.17-.3.4-.3.68h-1.51l-.02-.04c-.02-.6.22-1.11.71-1.51.49-.4,1.08-.59,1.81-.59.8,0,1.44.19,1.92.57.49.4.71.94.71,1.66,0,.34-.09.66-.32.96-.21.3-.5.55-.88.72.43.15.77.4.99.7.23.32.34.7.34,1.11,0,.72-.26,1.28-.79,1.7-.5.4-1.18.6-1.98.6-.73,0-1.35-.19-1.87-.59-.5-.38-.77-.92-.75-1.62l.02-.02h1.51c0,.28.09.53.32.74.2.19.49.28.8.28.36,0,.65-.09.86-.3.21-.19.32-.47.32-.81,0-.4-.11-.7-.32-.89-.21-.21-.5-.3-.9-.3h-.88v-1.17Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67-33.07h-29.34c-.53,0-.96-.43-.96-.96v-59.27c0-.53.43-.96.96-.96h44.01c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.63c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-339.05-34.99h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67-33.07c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.3c0-5.74,2.54-8.31,8.23-8.31h7.23c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-303.4-47.72c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-339.92"
            y="-93.31"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-327.29-81.68h-1.67v-1.49h1.67v1.49ZM-320.28-88.32h-2.19v6.64h-1.57v-6.64h-2.19v-1.23h5.94v1.23ZM-316.65-86.76l1.46-2.79h1.81l-2.32,3.91,2.45,3.96h-1.89l-1.5-2.83-1.51,2.83h-1.81l2.37-3.96-2.32-3.91h1.81l1.44,2.79ZM-307.07-88.32h-2.2v6.64h-1.55v-6.64h-2.19v-1.23h5.94v1.23Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-303.35-68.26h-9.68c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h9.68c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-303.35-61.46h-29.34c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.34c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-303.35-54.66h-17.92c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h17.92c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-318.02-47.89h-14.67c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h14.67c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-319.29-68.26h-13.4c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h13.4c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-325.48-54.66h-7.21c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.21c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58-33.09h-29.34c-.53,0-.96-.43-.96-.96v-59.25c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.28-.68.28ZM-417.96-35.01h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58-33.09c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.33,8.23-8.33h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.28-.68.28ZM-382.31-47.74c-4.6,0-6.31,1.74-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-418.83"
            y="-93.31"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-406.95-81.7h-1.55v-1.38h1.55v1.38ZM-403.81-84.55v2.85h-1.55v-7.85h3.1c.9,0,1.59.23,2.11.68.5.47.77,1.08.77,1.83s-.26,1.34-.77,1.81c-.52.45-1.21.68-2.11.68h-1.55ZM-403.81-85.75h1.55c.43,0,.77-.13.99-.36.22-.25.34-.55.34-.93s-.11-.68-.34-.92c-.22-.25-.56-.38-.99-.38h-1.55v2.59ZM-394.03-83.75c0-.3-.11-.53-.32-.72-.21-.19-.58-.36-1.12-.51-.92-.28-1.63-.6-2.09-.96-.47-.38-.71-.89-.71-1.53s.26-1.17.82-1.59c.54-.4,1.23-.6,2.07-.6s1.55.23,2.09.68c.52.47.78,1.04.77,1.7v.04h-1.51c0-.36-.13-.64-.35-.87-.24-.23-.58-.34-1.03-.34-.41,0-.73.09-.95.28-.22.19-.36.42-.36.72,0,.26.13.47.37.64.24.17.67.36,1.27.55.86.23,1.5.55,1.94.94.45.4.65.93.65,1.57s-.26,1.19-.79,1.59c-.52.4-1.21.58-2.07.58s-1.59-.23-2.22-.66c-.62-.43-.93-1.06-.92-1.85l.02-.04h1.51c0,.47.13.81.43,1.02.28.23.67.34,1.18.34.43,0,.75-.09.97-.26.22-.19.34-.42.34-.72h0ZM-391.28-81.7v-7.85h2.67c.93,0,1.72.3,2.34.93.62.6.94,1.4.94,2.36v1.3c0,.96-.32,1.74-.94,2.36-.62.6-1.4.91-2.34.91h-2.67ZM-389.73-88.34v5.44h1.03c.56,0,1.01-.19,1.33-.59.32-.38.47-.87.47-1.49v-1.3c0-.6-.15-1.1-.47-1.49-.32-.38-.77-.57-1.33-.57h-1.03Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-401.45-48.92c-.2,0-.39-.06-.56-.18l-1.79-1.28c-.21-.15-.35-.38-.39-.64-.04-.26.03-.52.19-.73,1.79-2.32,3.54-5.05,5.39-7.95,3.99-6.24,8.11-12.69,13.02-15.35.32-.17.72-.15,1.02.06.3.21.45.58.39.94-.87,5.35-5.54,10.97-10.06,16.41-2.4,2.89-4.68,5.62-6.4,8.29-.14.22-.37.37-.63.42-.06,0-.12.02-.18.02ZM-401.88-51.38l.18.13c1.71-2.51,3.88-5.12,5.98-7.64,3.64-4.38,7.38-8.88,8.97-13.07-3.65,2.97-6.99,8.2-10.24,13.28-1.67,2.62-3.26,5.1-4.89,7.3Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-408.68-39.17h0c-.58,0-1.21-.05-1.86-.16-.35-.06-.65-.31-.76-.65-.11-.34-.03-.71.22-.97,1.22-1.26,1.32-2.38,1.43-3.56.16-1.79.34-3.82,4.4-5.35.3-.11.64-.07.9.12l1.92,1.38c.19.14.33.34.38.58.32,1.45.33,3.71-.68,5.34-1.02,1.54-2.6,3.28-5.95,3.28ZM-405.04-47.88c-2.47,1.03-2.55,2-2.69,3.54-.08.94-.18,2.05-.84,3.24,2.46-.03,3.47-1.25,4.23-2.39.58-.94.67-2.39.49-3.54l-1.18-.85Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86-127.24h-29.34c-.53,0-.96-.43-.96-.96v-59.27c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.63c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.69.29ZM-181.24-129.16h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86-127.24c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.31c0-5.74,2.54-8.31,8.23-8.31h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.19.19-.43.29-.69.29ZM-145.59-141.89c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-182.11"
            y="-187.47"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-170.61-175.86h-1.66v-1.47h1.66v1.47ZM-167.4-178.71v2.85h-1.57v-7.85h3.1c.9,0,1.59.23,2.11.7.5.45.77,1.06.77,1.81s-.26,1.36-.77,1.81c-.52.45-1.21.68-2.11.68h-1.53ZM-167.4-179.92h1.53c.43,0,.77-.11.99-.36.22-.25.34-.55.34-.91,0-.38-.11-.7-.34-.94-.22-.25-.56-.36-.99-.36h-1.53v2.57ZM-155.55-175.86h-1.55l-3.14-5.36h-.04v5.36h-1.57v-7.85h1.57l3.14,5.36h.04v-5.36h1.55v7.85ZM-148.11-176.94c-.24.32-.6.59-1.08.83-.49.25-1.12.36-1.87.36-.93,0-1.72-.28-2.32-.89-.6-.6-.92-1.38-.92-2.32v-1.64c0-.94.3-1.72.88-2.32.58-.6,1.33-.91,2.24-.91s1.66.25,2.17.7c.5.47.77,1.08.77,1.85v.02h-1.48c-.02-.42-.15-.74-.39-.98-.24-.24-.58-.36-1.03-.36-.49,0-.86.19-1.16.55-.3.38-.43.85-.43,1.43v1.66c0,.58.15,1.08.45,1.43.3.38.71.57,1.21.57.37,0,.65-.04.88-.11.23-.08.39-.17.5-.28v-1.43h-1.38v-1.1h2.95v2.95Z"
          />
          <path
            class="cls-1"
            fill="#00b3e8"
            d="M-171.66-169.03h3.27v3.3h3.27v-3.3h3.29v3.3h3.27v-3.3h3.27v3.3h3.29v-3.3h3.27v3.3h-3.27v3.3h3.27v3.32h-3.27v3.3h3.27v3.3h-3.27v3.32h3.27v3.3h-3.27v-3.3h-3.29v3.3h-3.27v-3.3h-3.27v3.3h-3.29v-3.3h-3.27v3.3h-3.27v-3.3h3.27v-3.32h-3.27v-3.3h3.27v-3.3h-3.27v-3.32h3.27v-3.3h-3.27v-3.3ZM-158.56-165.73v3.3h3.27v-3.3h-3.27ZM-155.29-162.42v3.32h3.29v-3.32h-3.29ZM-165.12-165.73v3.3h3.29v-3.3h-3.29ZM-161.83-162.42v3.32h3.27v-3.32h-3.27ZM-158.56-159.1v3.3h3.27v-3.3h-3.27ZM-155.29-155.8v3.3h3.29v-3.3h-3.29ZM-161.83-149.17v-3.32h-3.29v3.32h3.29ZM-165.12-152.49v-3.3h-3.27v3.3h3.27ZM-155.29-149.17v-3.32h-3.27v3.32h3.27ZM-158.56-152.49v-3.3h-3.27v3.3h3.27ZM-161.83-155.8v-3.3h-3.29v3.3h3.29ZM-165.12-159.1v-3.32h-3.27v3.32h3.27Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77-127.26h-29.34c-.53,0-.96-.43-.96-.96v-59.26c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-260.15-129.18h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77-127.26c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.33,8.23-8.33h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-224.5-141.9c-4.6,0-6.31,1.73-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-261.02"
            y="-187.47"
            width="43.83"
            height="14.8"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-246.91-175.86h-1.68v-1.49h1.68v1.49ZM-243.69-177.09h3.79v1.23h-5.68v-.83l3.7-5.81h-3.7v-1.21h5.59v.77l-3.7,5.85ZM-237.17-175.86h-1.55v-7.85h1.55v7.85ZM-234.05-178.71v2.85h-1.57v-7.85h3.1c.9,0,1.61.23,2.11.68.5.45.77,1.08.77,1.81s-.26,1.36-.77,1.81c-.5.47-1.21.7-2.11.7h-1.53ZM-234.05-179.94h1.53c.43,0,.77-.11.99-.36.22-.23.34-.53.34-.91s-.11-.68-.34-.94c-.22-.24-.54-.36-.99-.36h-1.53v2.57Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-244.12-167.94h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-244.12-163.86h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-247.75-146.74c-.53,0-.96-.43-.96-.96v-26.32c0-.53.43-.96.96-.96s.96.43.96.96v26.32c0,.53-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-244.12-159.78h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-244.12-155.7h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-244.12-151.64h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-247.75-132.01c-1.75,0-3.49-.78-4.65-2.08-1.03-1.15-1.5-2.59-1.34-4.05l1.12-9.67c.06-.49.47-.85.96-.85h7.79c.49,0,.9.37.96.85l1.12,9.67c.17,1.46-.31,2.9-1.34,4.06-1.16,1.3-2.89,2.07-4.62,2.07ZM-250.8-146.74l-1.02,8.81c-.1.9.2,1.81.86,2.55.8.9,2,1.44,3.21,1.44s2.39-.54,3.19-1.43c.66-.74.97-1.65.86-2.56l-1.02-8.81h-6.08Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-247.75-135.09c-.99,0-1.96-.45-2.6-1.19-.55-.65-.79-1.45-.66-2.25l.28-1.7c.08-.47.48-.81.95-.81h4.02c.47,0,.87.34.95.81l.28,1.7c.13.8-.1,1.6-.66,2.25-.63.75-1.6,1.19-2.57,1.19ZM-248.95-139.11l-.15.89c-.04.24.04.47.22.69.27.31.71.52,1.13.52s.84-.2,1.11-.51c.13-.15.27-.39.22-.69l-.15-.89h-2.39Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67-127.24h-29.34c-.53,0-.96-.43-.96-.96v-59.28c0-.53.43-.96.96-.96h44.01c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.63c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-339.05-129.16h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67-127.24c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.31c0-5.75,2.54-8.31,8.23-8.31h7.23c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-303.4-141.89c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-339.92"
            y="-187.47"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-328.28-175.85h-1.66v-1.49h1.66v1.49ZM-322.47-177.54h-2.73l-.52,1.7h-1.57l2.67-7.87h1.59l2.67,7.87h-1.57l-.54-1.7ZM-324.8-178.75h1.96l-.97-3.12h-.02l-.97,3.12ZM-318.08-178.7v2.85h-1.55v-7.87h3.1c.9,0,1.59.25,2.11.7.5.45.77,1.06.77,1.81s-.26,1.36-.77,1.81c-.52.47-1.21.7-2.11.7h-1.55ZM-318.08-179.92h1.55c.43,0,.77-.11.99-.36.22-.23.34-.55.34-.91,0-.38-.11-.7-.34-.94-.23-.25-.56-.36-.99-.36h-1.55v2.57ZM-310.08-179.13h-.86v3.28h-1.57v-7.87h1.57v3.23h.67l2.06-3.23h1.92l-2.64,3.68,2.84,4.19h-1.91l-2.09-3.28Z"
          />
          <path
            class="cls-1"
            fill="#00b3e8"
            d="M-320.43-154.59l2.95-2.38c.34-.26.39-.76.13-1.09-.26-.36-.75-.42-1.08-.13l-3.14,2.49c-.97-.85-2.11-1.55-3.4-2.04-1.27-.47-2.58-.7-3.89-.74l-.71-3.95c-.08-.43-.49-.72-.9-.64-.43.07-.71.49-.64.91l.67,3.76c-4.19.51-7.98,3.28-9.57,7.55l-.02.04v.47c.08.23.22.41.47.49l20.39,7.76c.39.15.84-.04,1.01-.45,1.57-4.27.54-8.87-2.28-12.04h0ZM-338.24-151.02c2.21-4.79,7.76-7.14,12.73-5.25,4.97,1.91,7.61,7.36,6.15,12.42l-18.88-7.17Z"
          />
          <path
            class="cls-1"
            fill="#00b3e8"
            d="M-319.44-141.36l-20.39-7.76c-.06-.02-.13-.04-.19-.04v2.79l.37-1,18.93,7.21-4.47,11.95h1.66l4.52-12.14c.15-.4-.04-.85-.45-1.02h0Z"
          />
          <path
            class="cls-1"
            fill="#00b3e8"
            d="M-311.85-149.04c-1.4-.53-2.97.19-3.5,1.6l-3.85,10.29c-.52,1.44.19,3.02,1.59,3.55,1.4.55,2.99-.17,3.51-1.6l3.83-10.29c.54-1.42-.17-3-1.59-3.55h0ZM-315.55-135.75c-.22.6-.9.92-1.5.68-.62-.23-.92-.91-.69-1.51l3.85-10.29c.22-.62.9-.92,1.5-.7.6.23.91.91.67,1.53l-3.83,10.29Z"
          />
          <path
            class="cls-1"
            fill="#00b3e8"
            d="M-330.58-154.4c.58.23.86.87.65,1.43-.22.59-.86.87-1.42.66-.58-.23-.86-.87-.65-1.43.22-.58.86-.87,1.42-.66h0Z"
          />
          <path
            class="cls-1"
            fill="#00b3e8"
            d="M-322.94-151.49c.56.23.86.87.64,1.43-.21.59-.84.87-1.42.66-.56-.23-.86-.87-.63-1.44.2-.59.84-.87,1.42-.66h0Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58-127.24h-29.34c-.53,0-.96-.43-.96-.96v-59.28c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.63c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-417.96-129.16h27.98l13.94-14.08.16-43.27h-42.09v57.35Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58-127.24c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.31c0-5.74,2.54-8.31,8.23-8.31h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-382.31-141.88c-4.6,0-6.31,1.73-6.31,6.38v4.96l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-418.83"
            y="-187.47"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-408.25-175.85h-1.66v-1.49h1.66v1.49ZM-406.61-175.85v-7.87h2.67c.95,0,1.74.32,2.35.93.62.62.92,1.4.92,2.36v1.3c0,.96-.3,1.75-.92,2.36-.62.6-1.4.92-2.35.92h-2.67ZM-405.06-182.49v5.42h1.03c.56,0,1.01-.19,1.33-.57.32-.38.47-.89.47-1.49v-1.32c0-.6-.15-1.09-.47-1.47-.32-.38-.77-.57-1.33-.57h-1.03ZM-397.41-183.72l2,5.8h.02l2-5.8h2.04v7.87h-1.55v-2.36l.15-3.45-.04-.02-2.07,5.83h-1.05l-2.07-5.81-.04.02.15,3.44v2.36h-1.57v-7.87h2.04ZM-383.92-176.92c-.22.3-.6.57-1.08.81-.49.24-1.1.36-1.85.36-.95,0-1.74-.28-2.34-.89-.6-.6-.9-1.38-.9-2.32v-1.64c0-.94.28-1.72.86-2.32.58-.6,1.33-.91,2.24-.91s1.66.25,2.17.7c.5.47.77,1.1.79,1.85l-.02.04h-1.46c-.04-.43-.17-.76-.41-1-.22-.25-.58-.36-1.03-.36s-.86.19-1.16.55c-.28.38-.43.85-.43,1.43v1.66c0,.59.15,1.08.45,1.44.32.38.71.57,1.23.57.36,0,.65-.04.88-.11.21-.08.37-.17.48-.28v-1.43h-1.36v-1.1h2.93v2.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-383.06-145.5h-27.72c-1,0-1.81-.82-1.81-1.82v-8.91c0-.6.06-1.56.12-2.1.01-.1.04-.2.09-.29l5.66-10.64c.18-.88.99-1.56,1.89-1.56h16.26c.91,0,1.71.68,1.88,1.58l5.27,10.65c.04.09.07.18.08.27.06.59.1,1.55.1,2.1v8.91c0,1-.81,1.82-1.81,1.82ZM-410.89-158.02c-.05.52-.09,1.31-.09,1.78v8.91c0,.12.09.22.21.22h27.72c.12,0,.21-.09.21-.22v-8.91c0-.47-.03-1.27-.08-1.8l-5.26-10.63c-.04-.09-.07-.18-.08-.28-.02-.16-.18-.3-.3-.3h-16.26c-.13,0-.31.14-.32.3-.01.1-.04.2-.09.3l-5.64,10.62ZM-385.83-148.29h-22.19c-1,0-1.81-.82-1.81-1.82v-4.08c0-1,.81-1.82,1.81-1.82h22.19c1,0,1.81.82,1.81,1.82v4.08c0,1-.81,1.82-1.81,1.82ZM-408.01-154.41c-.12,0-.21.11-.21.22v4.08c0,.12.09.22.21.22h22.19c.12,0,.21-.09.21-.22v-4.08c0-.1-.09-.22-.21-.22h-22.19Z"
          />
          <path
            class="cls-1"
            fill="#00b3e8"
            d="M-394.93-153.17h-11.06c-.54,0-1.01.47-1.01,1.02s.47,1.02,1.01,1.02h11.06c.56,0,1.01-.45,1.01-1.02s-.45-1.02-1.01-1.02h0ZM-391.28-151.13c-.56,0-1.01-.45-1.01-1.02s.45-1,1.01-1,.99.45.99,1-.45,1.02-.99,1.02h0ZM-387.84-151.13c-.56,0-1.01-.45-1.01-1.02s.45-1,1.01-1,.99.45.99,1-.45,1.02-.99,1.02h0Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86-221.4h-29.34c-.53,0-.96-.43-.96-.96v-59.26c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.69.29ZM-181.24-223.33h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-152.86-221.4c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.33,8.23-8.33h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.19.19-.43.29-.69.29ZM-145.59-236.05c-4.6,0-6.31,1.73-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-182.11"
            y="-281.62"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-174.07-270.01h-1.68v-1.47h1.68v1.47ZM-172.45-270.01v-7.85h2.67c.95,0,1.74.3,2.36.93.62.6.93,1.4.93,2.36v1.3c0,.96-.32,1.74-.93,2.36-.62.6-1.4.91-2.36.91h-2.67ZM-170.88-276.66v5.44h1.03c.56,0,.99-.19,1.31-.58.32-.38.49-.87.49-1.49v-1.3c0-.6-.17-1.1-.49-1.49-.32-.38-.75-.57-1.31-.57h-1.03ZM-159.01-273.24c0,.96-.3,1.77-.91,2.4-.6.62-1.38.94-2.36.94s-1.72-.32-2.32-.94c-.6-.62-.9-1.43-.9-2.4v-1.42c0-.94.28-1.76.88-2.38.6-.62,1.38-.94,2.34-.94s1.76.32,2.36.94c.62.62.91,1.43.91,2.38v1.42ZM-160.58-274.66c0-.62-.15-1.13-.45-1.51-.3-.4-.71-.6-1.25-.6s-.93.21-1.23.59c-.28.4-.43.91-.43,1.53v1.42c0,.62.15,1.13.43,1.53.3.4.71.59,1.23.59s.95-.19,1.25-.59c.3-.4.45-.91.45-1.53v-1.42ZM-151.92-272.69l.02.04c.02.85-.24,1.51-.76,2-.52.51-1.25.76-2.19.76s-1.7-.3-2.28-.91c-.6-.62-.88-1.4-.88-2.36v-1.55c0-.96.28-1.74.86-2.36.56-.6,1.31-.91,2.21-.91.97,0,1.72.25,2.26.75.54.49.8,1.17.78,2.04v.02h-1.51c0-.51-.13-.91-.37-1.19-.24-.26-.64-.41-1.16-.41-.47,0-.82.19-1.1.59-.28.38-.41.87-.41,1.45v1.57c0,.6.15,1.1.43,1.47.3.38.67.57,1.18.57s.84-.13,1.08-.38c.22-.28.34-.66.34-1.19h1.51ZM-148.05-275.09l1.46-2.77h1.81l-2.3,3.89,2.43,3.96h-1.89l-1.5-2.83-1.5,2.83h-1.83l2.37-3.96-2.32-3.89h1.81l1.44,2.77Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-145.53-256.57h-29.34c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.34c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-145.53-249.8h-29.34c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.34c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-145.53-243h-29.34c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.34c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-160.2-236.2h-14.67c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h14.67c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77-221.4h-29.34c-.53,0-.96-.43-.96-.96v-59.26c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-260.15-223.33h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-231.77-221.4c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.32,8.23-8.32h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-224.5-236.05c-4.6,0-6.31,1.73-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-261.02"
            y="-281.62"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-252.08-270.01h-1.66v-1.47h1.66v1.47ZM-247.69-275.09l1.46-2.77h1.81l-2.3,3.89,2.43,3.96h-1.89l-1.5-2.83-1.5,2.83h-1.83l2.37-3.96-2.32-3.89h1.81l1.44,2.77ZM-241.92-271.22h3.46v1.21h-5.03v-7.85h1.57v6.64ZM-233.36-272.07c0-.3-.11-.55-.32-.72-.21-.19-.58-.36-1.12-.51-.92-.28-1.63-.6-2.09-.96-.47-.38-.71-.89-.71-1.53s.28-1.17.82-1.59c.54-.4,1.23-.6,2.07-.6s1.55.23,2.09.68c.52.47.79,1.04.77,1.7v.04h-1.51c0-.36-.11-.64-.35-.87-.24-.23-.58-.34-1.03-.34-.41,0-.73.09-.95.28-.22.19-.36.41-.36.72,0,.26.13.47.37.64.24.17.67.36,1.27.55.86.23,1.5.55,1.94.94.45.4.65.93.65,1.57s-.26,1.19-.79,1.59c-.52.4-1.21.59-2.07.59s-1.59-.23-2.22-.66c-.62-.45-.93-1.06-.91-1.85l.02-.04h1.51c0,.47.13.81.43,1.02.28.23.67.34,1.18.34.43,0,.75-.09.97-.26.22-.19.34-.41.34-.72h0ZM-227.88-275.09l1.46-2.77h1.83l-2.32,3.89,2.45,3.96h-1.89l-1.51-2.83-1.5,2.83h-1.81l2.36-3.96-2.3-3.89h1.79l1.44,2.77Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-221.32-254h-35.59c-.44,0-.8-.36-.8-.8v-5.62c0-.44.36-.8.8-.8h35.59c.44,0,.8.36.8.8v5.62c0,.44-.36.8-.8.8ZM-256.1-255.6h33.98v-4.02h-33.98v4.02Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-221.32-248.39h-35.59c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.59c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM-256.1-249.99h33.98v-4h-33.98v4Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-221.32-242.76h-35.59c-.44,0-.8-.36-.8-.8v-5.62c0-.44.36-.8.8-.8h35.59c.44,0,.8.36.8.8v5.62c0,.44-.36.8-.8.8ZM-256.1-244.37h33.98v-4.02h-33.98v4.02Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-232.27-242.76h-13.68c-.44,0-.8-.36-.8-.8v-16.86c0-.44.36-.8.8-.8h13.68c.44,0,.8.36.8.8v16.86c0,.44-.36.8-.8.8ZM-245.15-244.37h12.08v-15.25h-12.08v15.25Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M76.24-221.49h-29.38c-.53,0-.96-.43-.96-.96v-59.08c0-.53.43-.96.96-.96h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.68.28ZM47.83-223.41h28.02l13.96-14.04.16-43.12h-42.14v57.16Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M76.24-221.49c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.27c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.61c-.18.19-.43.28-.69.28ZM83.52-236.1c-4.6,0-6.31,1.73-6.31,6.38v4.93l11.25-11.31h-4.93Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="46.96"
            y="-281.54"
            width="43.89"
            height="14.78"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M86.7-253.99h-35.63c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM51.88-255.59h34.03v-4.01h-34.03v4.01Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M86.7-248.4h-35.63c-.44,0-.8-.36-.8-.8v-5.59c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.59c0,.44-.36.8-.8.8ZM51.88-250h34.03v-3.99h-34.03v3.99Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M86.7-242.79h-35.63c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM51.88-244.39h34.03v-4.01h-34.03v4.01Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M75.74-242.79h-13.7c-.44,0-.8-.36-.8-.8v-16.81c0-.44.36-.8.8-.8h13.7c.44,0,.8.36.8.8v16.81c0,.44-.36.8-.8.8ZM62.84-244.39h12.09v-15.21h-12.09v15.21Z"
          />
          <text
            class="cls-6"
            fill="#fefefe"
            transform="translate(54.48 -270.25)"
          >
            <tspan x="0" y="0">
              .CSV
            </tspan>
          </text>
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67-221.4h-29.34c-.53,0-.96-.43-.96-.96v-59.26c0-.53.43-.96.96-.96h44.01c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-339.05-223.33h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.67-221.4c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.33,8.23-8.33h7.23c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-303.4-236.05c-4.6,0-6.31,1.73-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-339.92"
            y="-281.62"
            width="43.83"
            height="14.8"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-331.33-270.01h-1.66v-1.49h1.66v1.49ZM-328.13-272.86v2.85h-1.55v-7.85h3.1c.9,0,1.59.23,2.11.68.51.47.77,1.08.77,1.81s-.26,1.36-.77,1.81c-.52.47-1.21.7-2.11.7h-1.55ZM-328.13-274.09h1.55c.43,0,.77-.11.99-.36.22-.23.34-.53.34-.91s-.11-.68-.34-.94c-.22-.24-.56-.36-.99-.36h-1.55v2.57ZM-320.99-272.86v2.85h-1.57v-7.85h3.1c.9,0,1.61.23,2.11.68.5.47.77,1.08.77,1.81s-.26,1.36-.77,1.81c-.5.47-1.22.7-2.11.7h-1.53ZM-320.99-274.09h1.53c.43,0,.77-.11.99-.36.22-.23.34-.53.34-.91s-.11-.68-.34-.94c-.22-.24-.54-.36-.99-.36h-1.53v2.57ZM-310.06-276.66h-2.21v6.64h-1.55v-6.64h-2.19v-1.21h5.94v1.21ZM-306.45-275.09l1.46-2.77h1.83l-2.32,3.89,2.45,3.96h-1.89l-1.5-2.83-1.51,2.83h-1.81l2.35-3.96-2.3-3.89h1.81l1.42,2.77Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-310.56-236.6c-.34,0-.66-.18-.84-.49l-3.35-5.95c-.26-.46-.1-1.05.37-1.31.46-.26,1.05-.1,1.31.37l3.35,5.95c.26.46.1,1.05-.37,1.31-.15.09-.31.12-.47.12ZM-325.46-236.6c-.16,0-.32-.04-.47-.12-.46-.26-.63-.85-.37-1.31l3.35-5.95c.26-.46.85-.63,1.31-.37.46.26.63.85.37,1.31l-3.35,5.95c-.18.31-.5.49-.84.49Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-318-259.52c-.53,0-.96-.43-.96-.96v-2.06c0-.53.43-.96.96-.96s.96.43.96.96v2.06c0,.53-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-302.47-242.55h-31.08c-.53,0-.96-.43-.96-.96v-16.97c0-.53.43-.96.96-.96h31.08c.53,0,.96.43.96.96v16.97c0,.53-.43.96-.96.96ZM-332.59-244.47h29.16v-15.05h-29.16v15.05Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-306.54-246.85c-.53,0-.96-.43-.96-.96v-8.21c0-.53.43-.96.96-.96s.96.43.96.96v8.21c0,.53-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-309.63-246.85c-.53,0-.96-.43-.96-.96l-.02-4.83c0-.53.43-.96.96-.97.52.02.96.43.97.96l.02,4.83c0,.53-.43.96-.96.97h0Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-312.73-246.85c-.53,0-.96-.43-.96-.96v-2.42c0-.53.43-.96.96-.96s.96.43.96.96v2.42c0,.53-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-320.08-255.06h-9.46c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h9.46c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-323.76-250.97h-5.78c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h5.78c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-329.53-246.85c-.53,0-.96-.43-.96-.96,0-.53.43-.96.96-.96l11.68-.02h0c.53,0,.96.43.96.96,0,.53-.43.96-.96.96l-11.68.02h0Z"
          />
        </g>
        <g>
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58-221.4h-29.34c-.53,0-.96-.43-.96-.96v-59.26c0-.53.43-.96.96-.96h44.02c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.61c0,.25-.1.49-.28.67l-14.5,14.65c-.18.18-.43.29-.68.29ZM-417.96-223.33h27.98l13.94-14.08.16-43.25h-42.09v57.33Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-389.58-221.4c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.29c0-5.76,2.54-8.33,8.23-8.33h7.23c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.5,14.65c-.18.19-.43.29-.68.29ZM-382.31-236.05c-4.6,0-6.31,1.73-6.31,6.4v4.95l11.23-11.35h-4.92Z"
          />
          <rect
            class="cls-3"
            fill="#00b3e8"
            x="-418.83"
            y="-281.62"
            width="43.83"
            height="14.82"
          />
          <path
            class="cls-2"
            fill="#fefefe"
            d="M-407.13-270.01h-1.66v-1.47h1.66v1.47ZM-403.94-273.15v3.13h-1.55v-7.85h2.93c.88,0,1.57.21,2.07.6.51.42.75.98.75,1.72,0,.4-.09.75-.32,1.04-.21.3-.5.53-.92.72.47.13.79.36.99.68.21.32.3.72.3,1.19v.57c0,.23.04.45.09.7.06.23.15.42.3.53v.11h-1.61c-.15-.11-.24-.3-.28-.58-.04-.26-.06-.51-.06-.76v-.57c0-.38-.11-.7-.32-.91-.22-.23-.52-.32-.9-.32h-1.49ZM-403.94-274.37h1.35c.45,0,.77-.09.99-.28.21-.17.32-.45.32-.83,0-.36-.11-.64-.32-.85-.22-.21-.54-.32-.95-.32h-1.38v2.28ZM-394.09-271.71h-2.73l-.52,1.7h-1.57l2.67-7.85h1.59l2.67,7.85h-1.57l-.54-1.7ZM-396.42-272.92h1.96l-.97-3.11h-.02l-.97,3.11ZM-389.69-273.15v3.13h-1.55v-7.85h2.93c.88,0,1.57.21,2.08.6.5.42.75.98.75,1.72,0,.4-.09.75-.32,1.04-.21.3-.51.53-.92.72.47.13.78.36.99.68.21.32.3.72.3,1.19v.57c0,.23.04.45.09.7.06.23.15.42.3.53v.11h-1.61c-.15-.11-.24-.3-.28-.58-.04-.26-.06-.51-.06-.76v-.57c0-.38-.11-.7-.32-.91-.22-.23-.52-.32-.9-.32h-1.5ZM-389.69-274.37h1.35c.45,0,.77-.09.99-.28.21-.17.32-.45.32-.83,0-.36-.11-.64-.32-.85-.22-.21-.54-.32-.95-.32h-1.38v2.28Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-401.94-262.08h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-401.94-258.01h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-405.56-240.89c-.53,0-.96-.43-.96-.96v-26.32c0-.53.43-.96.96-.96s.96.43.96.96v26.32c0,.53-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-401.94-253.93h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-401.94-249.85h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-401.94-245.77h-7.25c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.25c.53,0,.96.43.96.96s-.43.96-.96.96Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-405.56-226.14c-1.74,0-3.48-.78-4.64-2.08-1.04-1.16-1.51-2.61-1.35-4.07l1.12-9.67c.06-.49.47-.85.96-.85h7.79c.49,0,.9.37.96.85l1.12,9.67c.17,1.47-.31,2.91-1.35,4.08-1.16,1.3-2.89,2.08-4.62,2.08ZM-408.61-240.89l-1.02,8.81c-.1.91.2,1.82.87,2.57.8.9,2,1.44,3.2,1.44s2.38-.54,3.18-1.43c.67-.75.98-1.66.87-2.57l-1.02-8.81h-6.08Z"
          />
          <path
            class="cls-3"
            fill="#00b3e8"
            d="M-405.56-229.24c-.99,0-1.97-.45-2.6-1.2-.55-.64-.78-1.43-.65-2.22l.28-1.72c.08-.47.48-.81.95-.81h4.02c.47,0,.87.34.95.81l.28,1.72c.13.79-.1,1.58-.65,2.23-.63.75-1.6,1.19-2.58,1.19ZM-406.76-233.26l-.15.91c-.05.29.09.52.21.67.27.32.71.52,1.13.52s.85-.2,1.11-.52c.12-.15.26-.38.22-.67l-.15-.91h-2.38Z"
          />
        </g>
      </svg>
    </>
  );
}

export function Svgicon() {
  return (
    <svg
      fill="#00b3e8"
      width="45px"
      height="50px"
      version="1.1"
      id="_x32_"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          class="st0"
          d="M378.409,0H208.294h-13.176l-9.314,9.315L57.017,138.101l-9.314,9.315v13.176v265.513
		   c0,47.36,38.528,85.896,85.895,85.896h244.811c47.361,0,85.888-38.535,85.888-85.896V85.896C464.297,38.528,425.77,0,378.409,0z
			M432.493,426.104c0,29.877-24.214,54.091-54.084,54.091H133.598c-29.877,0-54.091-24.214-54.091-54.091V160.592h83.717
		   c24.884,0,45.07-20.179,45.07-45.071V31.804h170.114c29.87,0,54.084,24.214,54.084,54.091V426.104z"
        />
        <path
          class="st0"
          d="M180.296,296.668l-4.846-0.67c-10.63-1.487-14.265-4.978-14.265-10.104c0-5.78,4.309-9.817,12.383-9.817
		   c5.653,0,11.305,1.62,15.745,3.764c1.886,0.942,3.903,1.487,5.789,1.487c4.845,0,8.612-3.63,8.612-8.616
		   c0-3.226-1.481-5.921-4.71-7.939c-5.384-3.372-15.476-6.06-25.572-6.06c-19.781,0-32.436,11.171-32.436,27.998
		   c0,16.15,10.232,24.898,28.938,27.454l4.846,0.67c10.903,1.48,14.129,4.846,14.129,10.229c0,6.326-5.247,10.766-14.939,10.766
		   c-6.727,0-12.111-1.745-19.645-5.921c-1.616-0.942-3.634-1.62-5.788-1.62c-5.115,0-8.885,3.91-8.885,8.756
		   c0,3.226,1.616,6.326,4.713,8.344c6.054,3.764,15.878,7.8,28.798,7.8c23.823,0,35.934-12.24,35.934-28.795
		   C209.097,307.84,199.273,299.356,180.296,296.668z"
        />
        <path
          class="st0"
          d="M281.108,259.382c-4.577,0-7.939,2.43-9.556,7.674l-16.69,54.51h-0.402l-17.634-54.51
		   c-1.745-5.244-4.978-7.674-9.551-7.674c-5.653,0-9.692,4.176-9.692,9.287c0,1.347,0.269,2.834,0.67,4.175l23.286,68.104
		   c2.96,8.477,6.727,11.57,12.652,11.57c5.785,0,9.555-3.093,12.516-11.57l23.282-68.104c0.406-1.341,0.674-2.828,0.674-4.175
		   C290.664,263.558,286.76,259.382,281.108,259.382z"
        />
        <path
          class="st0"
          d="M364.556,300.836h-18.841c-5.114,0-8.344,3.1-8.344,7.806c0,4.713,3.23,7.814,8.344,7.814h6.193
		   c0.538,0,0.803,0.258,0.803,0.803c0,3.505-0.265,6.598-1.075,9.014c-1.882,5.796-7.67,9.426-14.669,9.426
		   c-7.943,0-12.921-3.903-14.939-10.096c-1.075-3.365-1.48-7.8-1.48-19.648c0-11.842,0.405-16.15,1.48-19.516
		   c2.018-6.325,6.867-10.228,14.67-10.228c5.924,0,10.362,1.885,13.859,6.724c2.695,3.777,5.387,4.852,8.749,4.852
		   c4.981,0,9.021-3.638,9.021-8.888c0-2.151-0.674-4.035-1.752-5.921c-4.842-8.204-15.071-14.264-29.877-14.264
		   c-16.287,0-28.935,7.408-33.644,22.204c-2.022,6.466-2.559,11.576-2.559,25.038c0,13.454,0.538,18.573,2.559,25.031
		   c4.709,14.802,17.357,22.204,33.644,22.204c16.286,0,28.668-8.204,33.374-22.881c1.617-5.111,2.29-12.645,2.29-20.716v-0.95
		   C372.362,303.664,369.538,300.836,364.556,300.836z"
        />
      </g>
    </svg>
  );
}

export function Docicon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.47c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.69.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.27c0-5.74,2.54-8.3,8.25-8.3h7.24c.39,0,.74.23.89.59s.07.77-.2,1.05l-14.52,14.61c-.19.18-.43.28-.69.28ZM37.63,46.4c-4.61,0-6.32,1.73-6.32,6.38v4.93l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.75"
        fill="#00b3e8"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M14.18,12.53h-1.67v-1.49h1.67v1.49ZM15.88,12.53V4.14h2.86c1.03,0,1.85.34,2.53.98.66.66.99,1.51.99,2.52v1.39c0,1.03-.34,1.86-.99,2.52-.67.64-1.5.98-2.53.98h-2.86ZM17.54,5.44v5.8h1.1c.62,0,1.09-.21,1.42-.62.34-.39.51-.92.51-1.58v-1.39c0-.66-.17-1.19-.51-1.6-.34-.4-.8-.6-1.42-.6h-1.1ZM30.27,9.09c0,1.03-.32,1.88-.97,2.56-.64.66-1.48,1-2.51,1s-1.87-.34-2.51-1c-.64-.68-.97-1.52-.97-2.56v-1.51c0-1.03.32-1.88.97-2.54.64-.68,1.46-1.02,2.49-1.02s1.89.34,2.53,1.02c.66.66.97,1.51.97,2.54v1.51ZM28.6,7.57c0-.66-.17-1.19-.49-1.62-.32-.41-.77-.62-1.35-.62s-1.01.21-1.33.62c-.3.41-.47.96-.47,1.62v1.52c0,.66.17,1.2.49,1.64.32.41.75.62,1.33.62s1.01-.21,1.33-.62c.32-.43.49-.98.49-1.64v-1.52ZM37.87,9.67l.02.04c.02.9-.26,1.62-.82,2.15-.56.53-1.35.79-2.34.79s-1.83-.32-2.45-.98c-.62-.64-.94-1.47-.94-2.5v-1.66c0-1.01.3-1.84.92-2.5.62-.66,1.4-.98,2.38-.98,1.03,0,1.83.26,2.41.79.58.55.86,1.26.86,2.18l-.02.04h-1.63c0-.56-.13-.98-.39-1.28-.26-.28-.67-.43-1.24-.43-.5,0-.9.21-1.18.6-.3.41-.45.94-.45,1.56v1.67c0,.64.15,1.17.47,1.58.3.39.73.6,1.25.6s.9-.13,1.16-.42c.24-.28.37-.71.37-1.26h1.61Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,25.92H8.3c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.38c.53,0,.97.43.97.96s-.43.96-.97.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,32.69H8.3c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.38c.53,0,.97.43.97.96s-.43.96-.97.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,39.47H8.3c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.38c.53,0,.97.43.97.96s-.43.96-.97.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M22.99,46.23h-14.69c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h14.69c.53,0,.97.43.97.96s-.43.96-.97.96Z"
      />
    </svg>
  );
}

export function Docxicon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      width="45px"
      height="50px"
      version="1.1"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.69.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.27c0-5.74,2.54-8.3,8.25-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.52,14.61c-.19.19-.43.28-.69.28ZM37.63,46.39c-4.61,0-6.32,1.73-6.32,6.38v4.93l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.78"
        fill="#00b3e8"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M9.1,12.53h-1.68v-1.47h1.68v1.47ZM10.73,12.53v-7.83h2.68c.96,0,1.74.3,2.36.92.62.6.94,1.39.94,2.35v1.3c0,.96-.32,1.73-.94,2.35-.62.6-1.4.9-2.36.9h-2.68ZM12.3,5.91v5.42h1.03c.56,0,.99-.19,1.31-.58.32-.38.49-.87.49-1.49v-1.3c0-.6-.17-1.09-.49-1.49-.32-.38-.75-.56-1.31-.56h-1.03ZM24.19,9.32c0,.96-.3,1.77-.92,2.39-.6.62-1.39.94-2.36.94s-1.72-.32-2.32-.94c-.6-.62-.9-1.43-.9-2.39v-1.41c0-.94.28-1.75.88-2.37.6-.62,1.39-.94,2.34-.94s1.76.32,2.36.94c.62.62.92,1.43.92,2.37v1.41ZM22.62,7.9c0-.62-.15-1.13-.45-1.51-.3-.4-.71-.6-1.25-.6s-.93.21-1.23.58c-.28.4-.43.9-.43,1.52v1.41c0,.62.15,1.13.43,1.52.3.4.71.58,1.23.58s.95-.19,1.25-.58c.3-.39.45-.9.45-1.52v-1.41ZM31.28,9.86l.02.04c.02.85-.24,1.51-.77,2-.52.51-1.25.75-2.19.75s-1.7-.3-2.28-.9c-.6-.62-.88-1.39-.88-2.35v-1.54c0-.96.28-1.73.86-2.35.56-.6,1.31-.9,2.21-.9.97,0,1.72.24,2.26.75.54.49.81,1.17.79,2.03v.02h-1.51c0-.51-.13-.9-.38-1.19-.24-.26-.64-.41-1.16-.41-.47,0-.82.19-1.11.58-.28.38-.41.87-.41,1.45v1.56c0,.6.15,1.09.43,1.47.3.38.67.56,1.18.56s.84-.13,1.09-.38c.22-.28.34-.66.34-1.19h1.52ZM35.15,7.47l1.46-2.77h1.81l-2.3,3.88,2.43,3.95h-1.89l-1.5-2.82-1.5,2.82h-1.83l2.38-3.95-2.32-3.88h1.81l1.44,2.77Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,25.94H8.3c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.38c.53,0,.97.43.97.96s-.43.96-.97.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,32.69H8.3c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.38c.53,0,.97.43.97.96s-.43.96-.97.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,39.47H8.3c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.38c.53,0,.97.43.97.96s-.43.96-.97.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M22.99,46.24h-14.69c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h14.69c.53,0,.97.43.97.96s-.43.96-.97.96Z"
      />
    </svg>
  );
}

export function Csvicon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.68.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.27c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.61c-.18.19-.43.28-.69.28ZM37.62,46.39c-4.6,0-6.31,1.73-6.31,6.38v4.93l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.78"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,28.5H5.17c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM5.98,26.9h34.03v-4.01H5.98v4.01Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,34.1H5.17c-.44,0-.8-.36-.8-.8v-5.59c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.59c0,.44-.36.8-.8.8ZM5.98,32.5h34.03v-3.99H5.98v3.99Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,39.7H5.17c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM5.98,38.1h34.03v-4.01H5.98v4.01Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M29.84,39.7h-13.7c-.44,0-.8-.36-.8-.8v-16.81c0-.44.36-.8.8-.8h13.7c.44,0,.8.36.8.8v16.81c0,.44-.36.8-.8.8ZM16.94,38.1h12.09v-15.21h-12.09v15.21Z"
      />
      <text class="cls-1" fill="#fefefe" transform="translate(8.58 12.24)">
        <tspan x="0" y="0">
          .CSV
        </tspan>
      </text>
    </svg>
  );
}

export function Xlsxicon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.68.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.27c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.61c-.18.19-.43.28-.69.28ZM37.62,46.39c-4.6,0-6.31,1.73-6.31,6.38v4.93l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.78"
        fill="#00b3e8"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M10,12.53h-1.67v-1.47h1.67v1.47ZM14.4,7.47l1.46-2.77h1.81l-2.3,3.88,2.43,3.95h-1.89l-1.5-2.82-1.5,2.82h-1.83l2.38-3.95-2.32-3.88h1.82l1.44,2.77ZM20.18,11.33h3.46v1.2h-5.03v-7.83h1.57v6.62ZM28.75,10.48c0-.3-.11-.55-.32-.72-.21-.19-.58-.36-1.12-.51-.92-.28-1.63-.6-2.1-.96-.47-.38-.71-.89-.71-1.52s.28-1.17.82-1.58c.54-.4,1.23-.6,2.08-.6s1.55.23,2.1.68c.52.47.79,1.04.77,1.69v.04h-1.52c0-.36-.11-.64-.35-.87-.24-.23-.58-.34-1.03-.34-.41,0-.73.09-.95.28-.22.19-.36.41-.36.72,0,.26.13.47.37.64.24.17.67.36,1.27.55.86.23,1.5.55,1.95.94.45.4.66.92.66,1.56s-.26,1.19-.79,1.58c-.52.4-1.22.58-2.08.58s-1.59-.23-2.23-.66c-.62-.45-.94-1.05-.92-1.85l.02-.04h1.52c0,.47.13.81.43,1.02.28.23.67.34,1.18.34.43,0,.75-.09.97-.26.22-.19.34-.41.34-.71h0ZM34.24,7.47l1.46-2.77h1.83l-2.32,3.88,2.45,3.95h-1.89l-1.51-2.82-1.5,2.82h-1.81l2.36-3.95-2.3-3.88h1.8l1.44,2.77Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,28.5H5.17c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM5.98,26.9h34.03v-4.01H5.98v4.01Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,34.1H5.17c-.44,0-.8-.36-.8-.8v-5.59c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.59c0,.44-.36.8-.8.8ZM5.98,32.5h34.03v-3.99H5.98v3.99Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,39.7H5.17c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM5.98,38.1h34.03v-4.01H5.98v4.01Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M29.84,39.7h-13.7c-.44,0-.8-.36-.8-.8v-16.81c0-.44.36-.8.8-.8h13.7c.44,0,.8.36.8.8v16.81c0,.44-.36.8-.8.8ZM16.94,38.1h12.09v-15.21h-12.09v15.21Z"
      />
    </svg>
  );
}

export function Mp4icon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.69.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.26c0-5.74,2.54-8.3,8.25-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.52,14.61c-.19.19-.43.28-.69.28ZM37.63,46.39c-4.61,0-6.32,1.73-6.32,6.38v4.93l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M12,12.25h-1.55v-1.37h1.55v1.37ZM15.64,4.42l1.98,5.78h.02l2-5.78h2.04v7.83h-1.55v-2.33l.15-3.46h-.04l-2.08,5.8h-1.05l-2.08-5.78h-.04l.15,3.44v2.33h-1.55v-7.83h2.04ZM24.71,9.41v2.84h-1.57v-7.83h3.11c.9,0,1.61.23,2.12.7.52.45.77,1.05.77,1.81s-.24,1.36-.77,1.81c-.51.45-1.22.68-2.12.68h-1.53ZM24.71,8.21h1.53c.45,0,.77-.13.99-.36.22-.24.34-.55.34-.92s-.11-.68-.34-.92c-.22-.24-.54-.38-.99-.38h-1.53v2.58ZM34.67,9.35h.88v1.2h-.88v1.69h-1.55v-1.69h-3.18l-.07-.94,3.24-5.2h1.57v4.93ZM31.32,9.35h1.8v-2.84h-.04l-.13.22-1.63,2.62Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M22.99,46.5c-7.85,0-14.24-6.42-14.24-14.31s6.39-14.31,14.24-14.31,14.26,6.42,14.26,14.31-6.4,14.31-14.26,14.31ZM22.99,19.47c-6.97,0-12.63,5.7-12.63,12.71s5.67,12.71,12.63,12.71,12.65-5.7,12.65-12.71-5.68-12.71-12.65-12.71Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M19.4,39.98c-.6,0-1.16-.32-1.46-.83-.16-.31-.22-.58-.22-.85v-12.24c0-.93.75-1.69,1.66-1.69.34,0,.65.1.92.28l5.21,3.01s.08.05.12.09l5.14,2.98c.8.48,1.07,1.5.61,2.28-.14.25-.35.46-.61.61l-5.16,3s-.06.05-.1.07l-5.28,3.07c-.27.14-.55.22-.84.22ZM19.4,25.98s-.08.03-.08.08v12.24s0,.06.03.1c0-.02.03-.02.05-.02.01,0,.03,0,.05,0l5.14-2.99s.06-.05.1-.07l5.28-3.07c.05-.06.03-.11,0-.13l-5.21-3.03s-.08-.05-.12-.09l-5.15-2.98s-.04-.03-.07-.04Z"
      />
    </svg>
  );
}

export function Mp3icon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.29-.68.29ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.03-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.73,2.54-8.28,8.24-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.6c-.18.19-.43.29-.69.29ZM37.62,46.4c-4.6,0-6.31,1.72-6.31,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect class="cls-2" x="1.06" y=".96" width="43.89" height="14.77" />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M13.22,48.02c-2.99,0-5.42-2.22-5.42-4.95s2.43-4.93,5.42-4.93c.91,0,1.78.2,2.56.58v-15.18c0-.71.52-1.31,1.23-1.42l14.57-1.88c.05,0,.12-.01.19-.01.35,0,.69.13.94.37.3.27.48.66.48,1.06v17.74c0,2.72-2.43,4.93-5.42,4.93s-5.42-2.21-5.42-4.93c0-2.88,2.31-4.89,5.61-4.89,1.2,0,2.34.27,3.3.75v-7.15l-13.55,1.76v10.77c0,.24.02.48.04.73.03.43.06.92.04,1.55-.06,3.14-1.81,5.1-4.56,5.1ZM13.22,40.06c-1.92,0-3.49,1.35-3.49,3.01s1.57,3.03,3.49,3.03c2.12,0,2.6-1.76,2.63-3.24.02-.54,0-.96-.04-1.36-.01-.16-.02-.32-.03-.47-.65-.62-1.57-.97-2.56-.97ZM27.95,36.43c-1.7,0-3.68.78-3.68,2.97,0,1.66,1.56,3.01,3.49,3.01s3.49-1.35,3.49-3.01v-1.83c-.77-.71-1.99-1.13-3.3-1.13ZM17.7,23.96v3.98l13.55-1.76v-3.97l-13.55,1.75Z"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M12.12,12.23h-1.55v-1.37h1.55v1.37ZM15.75,4.46l1.98,5.72h.02l2-5.72h2.06v7.77h-1.57v-2.31l.15-3.44h-.04l-2.08,5.76h-1.05l-2.08-5.74h-.04l.15,3.42v2.31h-1.55v-7.77h2.04ZM24.82,9.41v2.82h-1.55v-7.77h3.09c.9,0,1.61.23,2.11.68.52.45.79,1.05.79,1.81s-.26,1.34-.79,1.79c-.51.45-1.22.68-2.11.68h-1.54ZM24.82,8.2h1.54c.45,0,.77-.11.99-.36.22-.22.34-.53.34-.9s-.11-.68-.34-.92c-.21-.24-.54-.36-.99-.36h-1.54v2.54ZM31.79,7.68h.88c.35,0,.64-.09.82-.28.17-.21.26-.47.26-.81,0-.32-.09-.56-.26-.75-.19-.19-.45-.28-.79-.28-.28,0-.54.08-.73.24-.21.17-.3.39-.3.68h-1.52l-.02-.04c-.02-.6.22-1.11.71-1.51.49-.39,1.09-.58,1.82-.58.8,0,1.44.19,1.93.56.49.4.71.94.71,1.66,0,.34-.09.66-.32.96-.21.3-.5.55-.88.71.43.15.77.39.99.7.23.32.34.7.34,1.11,0,.71-.26,1.28-.79,1.69-.5.4-1.18.6-1.98.6-.73,0-1.35-.19-1.87-.58-.5-.38-.77-.92-.75-1.62l.02-.02h1.52c0,.28.09.53.32.73.21.19.49.28.8.28.36,0,.66-.09.86-.3.21-.19.32-.47.32-.81,0-.4-.11-.7-.32-.88-.21-.21-.5-.3-.9-.3h-.88v-1.17Z"
      />
    </svg>
  );
}

export function Txticon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.28-.69.28ZM1.93,59.08h28.02l13.96-14.04.16-43.13H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.73,2.54-8.28,8.24-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.52,14.6c-.18.18-.43.28-.69.28ZM37.63,46.4c-4.6,0-6.32,1.72-6.32,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.88"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M13.71,12.55h-1.67v-1.49h1.67v1.49ZM20.73,5.93h-2.19v6.62h-1.57v-6.62h-2.19v-1.22h5.95v1.22ZM24.36,7.49l1.46-2.78h1.82l-2.32,3.89,2.45,3.95h-1.89l-1.5-2.82-1.52,2.82h-1.82l2.38-3.95-2.32-3.89h1.82l1.44,2.78ZM33.96,5.93h-2.21v6.62h-1.55v-6.62h-2.19v-1.22h5.95v1.22Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,25.93h-9.69c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h9.69c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,32.7H8.3c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h29.38c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M37.68,39.47h-17.95c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h17.95c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M22.99,46.23h-14.69c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h14.69c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M21.72,25.93h-13.42c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h13.42c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M15.52,39.47h-7.22c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.22c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
    </svg>
  );
}

export function PptIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.42.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.28-.68.28ZM1.93,59.08h28.02l13.96-14.04.16-43.13H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.73,2.54-8.28,8.24-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.6c-.18.19-.43.28-.68.28ZM37.62,46.4c-4.6,0-6.32,1.72-6.32,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M13.18,12.53h-1.67v-1.47h1.67v1.47ZM16.38,9.69v2.84h-1.55v-7.83h3.11c.88,0,1.59.22,2.11.7.51.45.77,1.05.77,1.8s-.26,1.35-.77,1.81c-.52.45-1.24.68-2.11.68h-1.55ZM16.38,8.49h1.55c.43,0,.75-.11.97-.36.22-.24.34-.55.34-.9,0-.38-.11-.7-.32-.94-.22-.25-.56-.36-.99-.36h-1.55v2.56ZM23.53,9.69v2.84h-1.57v-7.83h3.11c.9,0,1.59.22,2.12.7.51.45.77,1.05.77,1.8s-.26,1.35-.77,1.81c-.52.45-1.22.68-2.12.68h-1.53ZM23.53,8.49h1.53c.43,0,.77-.11.99-.36.22-.24.34-.55.34-.9,0-.38-.11-.7-.34-.94-.22-.25-.56-.36-.99-.36h-1.53v2.56ZM34.48,5.93h-2.21v6.6h-1.55v-6.6h-2.19v-1.22h5.95v1.22Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.46,45.85c-.34,0-.66-.18-.84-.49l-3.35-5.95c-.26-.46-.09-1.05.37-1.31.46-.26,1.05-.1,1.31.37l3.35,5.95c.26.46.1,1.05-.37,1.31-.15.08-.31.12-.47.12ZM15.54,45.85c-.16,0-.32-.04-.47-.12-.46-.26-.63-.84-.37-1.31l3.35-5.95c.26-.46.85-.62,1.31-.37.46.26.63.84.37,1.31l-3.35,5.95c-.18.31-.5.49-.84.49Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M23.01,23.01c-.53,0-.96-.43-.96-.96v-2.05c0-.53.43-.96.96-.96s.96.43.96.96v2.05c0,.53-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M38.56,39.91H7.44c-.53,0-.96-.43-.96-.96v-16.9c0-.53.43-.96.96-.96h31.12c.53,0,.96.43.96.96v16.9c0,.53-.43.96-.96.96ZM8.4,37.99h29.19v-14.98H8.4v14.98Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M34.48,35.62c-.53,0-.96-.43-.96-.96v-8.18c0-.53.43-.96.96-.96s.96.43.96.96v8.18c0,.53-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M31.39,35.62c-.53,0-.96-.43-.96-.96l-.02-4.8c0-.53.43-.96.96-.96h0c.53,0,.96.43.96.96l.02,4.8c0,.53-.43.96-.96.96h0Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M28.29,35.62c-.53,0-.96-.43-.96-.96v-2.41c0-.53.43-.96.96-.96s.96.43.96.96v2.41c0,.53-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M20.93,27.43h-9.47c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h9.47c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M17.25,31.54h-5.78c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h5.78c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M23.16,35.62h-11.7c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h11.7c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
    </svg>
  );
}

export function PsdIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 60.98"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,60.99H.96c-.53,0-.96-.43-.96-.96V.95C0,.42.43-.01.96-.01h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.68.28ZM1.93,59.07h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,60.99c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.26c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.52,14.61c-.18.19-.43.28-.68.28ZM37.62,46.38c-4.6,0-6.32,1.73-6.32,6.38v4.93l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".95"
        width="43.89"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M12.96,12.52h-1.55v-1.37h1.55v1.37ZM16.1,9.68v2.84h-1.55v-7.83h3.11c.9,0,1.59.23,2.12.68.51.47.77,1.07.77,1.83s-.26,1.34-.77,1.81c-.52.45-1.22.68-2.12.68h-1.55ZM16.1,8.48h1.55c.43,0,.77-.13.99-.36.22-.24.34-.55.34-.92s-.11-.68-.34-.92c-.22-.24-.56-.38-.99-.38h-1.55v2.58ZM25.89,10.47c0-.3-.11-.53-.32-.72-.21-.19-.58-.36-1.12-.51-.92-.28-1.63-.6-2.1-.96-.47-.38-.71-.88-.71-1.52s.26-1.17.82-1.58c.54-.39,1.24-.6,2.08-.6s1.55.23,2.1.68c.52.47.79,1.03.77,1.69v.04h-1.52c0-.36-.13-.64-.35-.87-.24-.22-.58-.34-1.03-.34-.41,0-.73.09-.95.28-.22.19-.36.41-.36.72,0,.26.13.47.37.64.24.17.67.36,1.27.55.86.23,1.5.55,1.95.94.45.4.65.92.65,1.56s-.26,1.19-.79,1.58c-.52.39-1.22.58-2.08.58s-1.59-.23-2.23-.66c-.62-.43-.94-1.05-.92-1.84l.02-.04h1.52c0,.47.13.81.43,1.02.28.23.67.34,1.18.34.43,0,.75-.09.97-.26.22-.19.34-.41.34-.72h0ZM28.64,12.52v-7.83h2.68c.94,0,1.72.3,2.34.92.62.6.94,1.39.94,2.35v1.3c0,.96-.32,1.73-.94,2.35-.62.6-1.4.9-2.34.9h-2.68ZM30.19,5.9v5.42h1.03c.56,0,1.01-.19,1.33-.58.32-.38.47-.87.47-1.49v-1.3c0-.6-.15-1.09-.47-1.49-.32-.38-.77-.56-1.33-.56h-1.03Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M18.46,45.2c-.2,0-.4-.06-.56-.18l-1.8-1.28c-.21-.15-.35-.38-.39-.64-.04-.26.03-.52.19-.73,1.79-2.31,3.55-5.04,5.4-7.93,3.99-6.22,8.12-12.65,13.03-15.31.32-.17.72-.15,1.02.06.3.21.45.58.39.94-.87,5.34-5.55,10.94-10.07,16.36-2.41,2.88-4.68,5.61-6.41,8.27-.14.22-.37.37-.63.42-.06,0-.12.02-.18.02ZM18.03,42.75l.18.13c1.72-2.51,3.88-5.1,5.99-7.62,3.64-4.37,7.39-8.86,8.98-13.03-3.65,2.96-7,8.18-10.25,13.24-1.67,2.61-3.27,5.09-4.9,7.28Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M11.22,54.93h0c-.58,0-1.21-.05-1.86-.16-.35-.06-.65-.31-.76-.65-.11-.34-.03-.71.22-.97,1.23-1.26,1.32-2.37,1.43-3.55.16-1.79.34-3.81,4.41-5.34.3-.11.64-.07.9.12l1.93,1.37c.19.14.33.34.38.57.32,1.44.33,3.7-.68,5.32-1.02,1.53-2.6,3.27-5.96,3.27ZM14.87,46.25c-2.47,1.03-2.56,1.99-2.69,3.52-.08.94-.18,2.05-.84,3.23,2.46-.03,3.48-1.25,4.23-2.39.58-.94.67-2.39.49-3.53l-1.19-.85Z"
      />
    </svg>
  );
}

export function DmgIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-3"
        fill="#00b3e8"
        d="M30.35,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.28-.68.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-3"
        fill="#00b3e8"
        d="M30.35,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.73,2.54-8.28,8.24-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.6c-.18.19-.43.28-.68.28ZM37.62,46.4c-4.6,0-6.32,1.72-6.32,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-3"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.77"
      />
      <path
        class="cls-2"
        fill="#fefefe"
        d="M11.65,12.55h-1.67v-1.49h1.67v1.49ZM13.3,12.55v-7.85h2.68c.95,0,1.74.32,2.36.92.62.62.92,1.39.92,2.35v1.3c0,.96-.3,1.75-.92,2.35-.62.6-1.4.92-2.36.92h-2.68ZM14.85,5.93v5.4h1.03c.56,0,1.01-.19,1.33-.56.32-.38.47-.88.47-1.49v-1.32c0-.6-.15-1.09-.47-1.47-.32-.38-.77-.56-1.33-.56h-1.03ZM22.5,4.7l2,5.78h.02l2-5.78h2.04v7.85h-1.55v-2.35l.15-3.44-.04-.02-2.08,5.81h-1.05l-2.08-5.79-.04.02.15,3.42v2.35h-1.57v-7.85h2.04ZM36.02,11.48c-.22.3-.6.56-1.09.81-.49.24-1.1.36-1.85.36-.95,0-1.74-.28-2.34-.88-.6-.6-.9-1.37-.9-2.31v-1.64c0-.94.28-1.71.86-2.31.58-.6,1.33-.9,2.25-.9s1.67.24,2.17.7c.51.47.77,1.09.79,1.84l-.02.04h-1.46c-.04-.43-.17-.75-.41-1-.22-.24-.58-.36-1.03-.36s-.86.19-1.16.55c-.28.38-.43.85-.43,1.43v1.66c0,.58.15,1.07.45,1.43.32.38.71.56,1.23.56.36,0,.66-.04.88-.11.21-.08.37-.17.49-.28v-1.43h-1.37v-1.09h2.94v2.95Z"
      />
      <path
        class="cls-3"
        fill="#00b3e8"
        d="M36.88,42.8H9.12c-1,0-1.81-.81-1.81-1.82v-8.88c0-.59.06-1.55.12-2.09.01-.1.04-.2.09-.29l5.66-10.61c.18-.87.99-1.56,1.9-1.56h16.28c.92,0,1.71.68,1.88,1.57l5.28,10.61c.04.09.07.18.08.27.06.59.1,1.54.1,2.09v8.88c0,1-.81,1.82-1.81,1.82ZM9.01,30.32c-.05.52-.09,1.3-.09,1.78v8.88c0,.12.09.22.21.22h27.75c.12,0,.21-.09.21-.22v-8.88c0-.47-.03-1.26-.08-1.79l-5.27-10.59c-.04-.09-.07-.18-.08-.28-.02-.16-.18-.3-.3-.3H15.07c-.13,0-.31.14-.32.3-.01.1-.04.2-.09.3l-5.65,10.58ZM34.11,40.01H11.89c-1,0-1.81-.81-1.81-1.82v-4.06c0-1,.81-1.82,1.81-1.82h22.21c1,0,1.81.81,1.81,1.82v4.06c0,1-.81,1.82-1.81,1.82ZM11.89,33.92c-.12,0-.21.11-.21.22v4.06c0,.12.09.22.21.22h22.21c.12,0,.21-.09.21-.22v-4.06c0-.1-.09-.22-.21-.22H11.89Z"
      />
      <path
        class="cls-1"
        fill="#00b3e8"
        d="M24.99,35.15h-11.08c-.54,0-1.01.47-1.01,1.02s.47,1.02,1.01,1.02h11.08c.56,0,1.01-.45,1.01-1.02s-.45-1.02-1.01-1.02h0ZM28.64,37.18c-.56,0-1.01-.45-1.01-1.02s.45-1,1.01-1,.99.45.99,1-.45,1.02-.99,1.02h0ZM32.09,37.18c-.56,0-1.01-.45-1.01-1.02s.45-1,1.01-1,.99.45.99,1-.45,1.02-.99,1.02h0Z"
      />
    </svg>
  );
}

export function AiIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.42.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.28-.69.28ZM1.93,59.08h28.02l13.96-14.04.16-43.13H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.73,2.54-8.28,8.25-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.52,14.6c-.19.18-.43.28-.69.28ZM37.63,46.4c-4.61,0-6.32,1.72-6.32,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M18.35,12.53h-1.68v-1.47h1.68v1.47ZM24.51,10.72h-2.92l-.56,1.81h-1.7l2.88-8.38h1.7l2.87,8.38h-1.68l-.58-1.81ZM22,9.45h2.1l-1.03-3.33h-.04l-1.03,3.33ZM29.32,12.53h-1.67V4.16h1.67v8.38Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M38.97,29.63v-5.68h-5.63v1.9h-5.63v-3.78h-9.41v3.78h-5.63v-1.9h-5.65v5.68h5.65v-1.9h3.33c-4.31,2.5-7.09,7.17-7.09,12.29,0,.53.41.96.93.96s.94-.43.94-.96c0-4.95,3.03-9.45,7.52-11.33v2.82h9.41v-2.82c4.47,1.88,7.52,6.38,7.52,11.33,0,.53.41.96.93.96s.94-.43.94-.96c0-5.12-2.81-9.79-7.09-12.29h3.33v1.9h5.63ZM10.77,27.73h-1.87v-1.88h1.87v1.88ZM25.82,29.63h-5.63v-5.68h5.63v5.68ZM35.23,25.85h1.87v1.88h-1.87v-1.88Z"
      />
    </svg>
  );
}

export function HtmlIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28s.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.29-.69.29ZM1.93,59.08h28.02l13.96-14.04.16-43.13H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61c-.12,0-.25-.03-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.72,2.54-8.28,8.24-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.21,1.05l-14.52,14.6c-.18.19-.43.29-.69.29ZM37.63,46.4c-4.6,0-6.32,1.72-6.32,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.88"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M8.71,12.55h-1.67v-1.49h1.67v1.49ZM16.65,12.55h-1.55v-3.26h-3.18v3.26h-1.55v-7.85h1.55v3.37h3.18v-3.37h1.55v7.85ZM23.5,5.93h-2.21v6.62h-1.55v-6.62h-2.21v-1.22h5.97v1.22ZM26.4,4.71l2,5.77h.02l2-5.77h2.04v7.85h-1.55v-2.35l.15-3.44-.04-.02-2.08,5.81h-1.05l-2.08-5.8-.04.02.15,3.42v2.35h-1.57v-7.85h2.04ZM35.49,11.33h3.46v1.22h-5.03v-7.85h1.57v6.62Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M17.15,40.98c-.32,0-.63-.12-.88-.35l-7.29-6.88s-.02-.02-.03-.03c-.11-.11-.19-.23-.26-.36-.08-.18-.12-.36-.12-.55,0-.18.04-.37.12-.54.06-.13.15-.26.26-.36,0,0,.02-.02.03-.03l7.29-6.9c.51-.49,1.33-.47,1.82.05.49.51.47,1.32-.05,1.81l-6.32,5.97,6.32,5.96c.52.48.54,1.29.05,1.81-.25.26-.59.4-.93.4ZM28.83,40.98c-.34,0-.68-.13-.94-.4-.49-.51-.46-1.32.05-1.81l6.33-5.96-6.33-5.97c-.52-.49-.54-1.3-.05-1.81.49-.51,1.3-.53,1.82-.05l7.31,6.9s.02.02.03.04c.1.11.19.22.25.35h0c.08.18.13.37.13.56s-.04.38-.13.55c-.06.13-.14.25-.25.35-.01,0-.02.02-.04.03l-7.31,6.88c-.25.23-.57.35-.88.35Z"
      />
    </svg>
  );
}

export function CssIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.47c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.68.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.35,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.26c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.52,14.61c-.18.18-.43.28-.68.28ZM37.62,46.4c-4.6,0-6.32,1.73-6.32,6.38v4.93l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M12.44,12.53h-1.68v-1.47h1.68v1.47ZM20.43,9.67v.04c.02.9-.26,1.62-.82,2.15-.56.53-1.35.79-2.34.79s-1.82-.32-2.45-.98c-.62-.64-.94-1.47-.94-2.5v-1.66c0-1.01.3-1.84.92-2.5.62-.66,1.4-.98,2.38-.98,1.03,0,1.83.26,2.41.79.58.55.88,1.26.86,2.18l-.02.04h-1.63c0-.56-.13-.98-.39-1.28-.26-.28-.67-.43-1.23-.43-.49,0-.9.21-1.18.6-.3.41-.45.94-.45,1.56v1.67c0,.64.15,1.17.47,1.58.3.39.73.6,1.25.6s.92-.13,1.16-.42c.24-.28.37-.71.37-1.26h1.63ZM26.12,10.35c0-.34-.11-.58-.34-.79-.22-.19-.62-.38-1.2-.55-.99-.28-1.74-.62-2.25-1.02-.51-.39-.77-.94-.77-1.64s.3-1.24.88-1.67c.58-.43,1.33-.66,2.23-.66s1.67.25,2.25.73c.58.49.86,1.09.84,1.81l-.02.04h-1.63c0-.38-.13-.7-.37-.92-.26-.24-.62-.36-1.09-.36s-.8.09-1.05.3c-.24.19-.36.45-.36.75,0,.28.13.51.39.7.26.19.71.38,1.35.57.92.26,1.61.6,2.1,1.03.47.41.71.96.71,1.66s-.28,1.28-.84,1.69c-.58.41-1.31.62-2.25.62s-1.7-.23-2.38-.7c-.67-.47-.99-1.13-.97-1.98v-.04h1.63c0,.49.15.87.47,1.09.3.23.71.34,1.25.34.47,0,.8-.09,1.05-.26.24-.21.36-.45.36-.75h0ZM33.56,10.35c0-.34-.11-.58-.34-.79-.22-.19-.64-.38-1.2-.55-.99-.28-1.74-.62-2.24-1.02-.51-.39-.77-.94-.77-1.64s.3-1.24.88-1.67c.58-.43,1.31-.66,2.23-.66s1.67.25,2.23.73c.58.49.86,1.09.84,1.81v.04h-1.63c0-.38-.13-.7-.39-.92-.24-.24-.62-.36-1.09-.36s-.79.09-1.03.3c-.24.19-.37.45-.37.75,0,.28.13.51.39.7.26.19.71.38,1.37.57.92.26,1.61.6,2.08,1.03.49.41.71.96.71,1.66s-.28,1.28-.84,1.69c-.56.41-1.31.62-2.23.62s-1.7-.23-2.38-.7c-.67-.47-1.01-1.13-.99-1.98l.02-.04h1.63c0,.49.15.87.45,1.09.3.23.73.34,1.27.34.45,0,.8-.09,1.05-.26.24-.21.35-.45.35-.75h0Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M28.08,41.78c-.53,0-.96-.43-.96-.96s.43-.96.96-.96c.7,0,1.36,0,1.6-.23.12-.11.31-.46.3-1.47l-.06-2.86c-.08-2.14.66-3.37,1.29-4.02.04-.04.07-.07.11-.11-.04-.03-.07-.07-.11-.11-.63-.65-1.36-1.87-1.29-4.03l.06-2.88c0-.99-.19-1.33-.3-1.45-.23-.23-.9-.23-1.6-.23-.53,0-.96-.43-.96-.96s.43-.96.96-.96c1.07,0,2.17,0,2.97.8.6.6.87,1.49.86,2.82l-.04,2.17s0,0,0,.01l-.02.73c-.04,1.17.22,2.1.75,2.64.31.32.69.48,1.13.48.53,0,.96.43.96.96s-.43.96-.96.96c-.44,0-.82.16-1.13.48-.52.54-.79,1.47-.75,2.63l.02.74s0,0,0,0h0l.04,2.15c0,1.35-.26,2.25-.86,2.85-.8.8-1.9.8-2.96.8ZM17.9,41.78c-1.06,0-2.16,0-2.96-.8-.6-.6-.87-1.51-.85-2.84l.02-2.13s0-.02,0-.03c0,0,0-.02,0-.03l.04-.73c.04-1.14-.22-2.07-.74-2.61-.31-.32-.7-.49-1.16-.49-.53,0-.96-.43-.96-.96s.43-.96.96-.96c.45,0,.84-.16,1.16-.49.52-.54.79-1.47.74-2.62l-.04-.71s0-.02,0-.03,0-.02,0-.03l-.02-2.16c-.02-1.32.25-2.22.84-2.82.8-.8,1.9-.8,2.96-.8.53,0,.96.43.96.96s-.43.96-.96.96c-.7,0-1.36,0-1.59.23-.11.11-.3.46-.29,1.45l.06,2.86c.08,2.17-.66,3.39-1.28,4.04-.04.04-.08.08-.12.11.04.04.08.08.12.11.63.65,1.36,1.87,1.28,4.02l-.04.73-.02,2.12c-.01,1.01.18,1.35.29,1.47.23.23.89.23,1.59.23.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
    </svg>
  );
}

export function XlsIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.42.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.28-.68.28ZM1.93,59.08h28.02l13.96-14.04.16-43.13H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.73,2.54-8.28,8.24-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.04l-14.52,14.6c-.18.19-.43.28-.69.28ZM37.62,46.4c-4.6,0-6.31,1.72-6.31,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.77"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M13.67,12.55h-1.67v-1.49h1.67v1.49ZM18.07,7.49l1.46-2.78h1.82l-2.3,3.9,2.43,3.95h-1.89l-1.5-2.82-1.5,2.82h-1.84l2.38-3.95-2.32-3.9h1.82l1.44,2.78ZM23.85,11.33h3.46v1.22h-5.02v-7.85h1.55v6.62ZM32.42,10.5c0-.3-.11-.55-.32-.72-.21-.19-.58-.36-1.1-.53-.94-.26-1.65-.58-2.12-.96-.47-.36-.71-.86-.71-1.5s.28-1.17.82-1.58c.54-.41,1.23-.6,2.08-.6s1.55.23,2.1.68c.54.45.81,1.02.79,1.69l-.02.04h-1.52c0-.36-.11-.66-.35-.87-.24-.23-.58-.34-1.01-.34s-.75.1-.97.28c-.22.19-.34.41-.34.7,0,.26.11.49.35.66.24.17.67.34,1.27.53.86.25,1.52.57,1.95.96.45.4.67.9.67,1.54,0,.68-.26,1.21-.8,1.6-.52.38-1.22.58-2.08.58s-1.59-.23-2.23-.68c-.62-.43-.94-1.05-.92-1.84l.02-.02h1.52c0,.45.15.81.43,1.02.28.22.67.32,1.18.32.43,0,.75-.08.97-.26.22-.17.34-.4.34-.7h0Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,28.49H5.17c-.44,0-.8-.36-.8-.8v-5.59c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.59c0,.44-.36.8-.8.8ZM5.98,26.9h34.03v-3.99H5.98v3.99Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,34.1H5.17c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM5.98,32.5h34.03v-4.01H5.98v4.01Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M40.81,39.71H5.17c-.44,0-.8-.36-.8-.8v-5.61c0-.44.36-.8.8-.8h35.63c.44,0,.8.36.8.8v5.61c0,.44-.36.8-.8.8ZM5.98,38.11h34.03v-4.01H5.98v4.01Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M29.84,39.71h-13.7c-.44,0-.8-.36-.8-.8v-16.8c0-.44.36-.8.8-.8h13.7c.44,0,.8.36.8.8v16.8c0,.44-.36.8-.8.8ZM16.94,38.11h12.09v-15.2h-12.09v15.2Z"
      />
    </svg>
  );
}

export function ZipIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.47c0,.25-.1.49-.28.67l-14.52,14.61c-.18.18-.43.28-.68.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.34,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.26c0-5.74,2.54-8.3,8.24-8.3h7.24c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.52,14.61c-.18.19-.43.28-.69.28ZM37.62,46.39c-4.6,0-6.31,1.73-6.31,6.38v4.93l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.89"
        height="14.76"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M15.19,12.54h-1.68v-1.49h1.68v1.49ZM18.4,11.31h3.8v1.22h-5.69v-.83l3.71-5.8h-3.71v-1.21h5.6v.77l-3.71,5.84ZM24.94,12.54h-1.55v-7.83h1.55v7.83ZM28.06,9.69v2.84h-1.57v-7.83h3.11c.9,0,1.61.23,2.12.68.5.45.77,1.07.77,1.81s-.26,1.35-.77,1.81c-.51.47-1.22.7-2.12.7h-1.53ZM28.06,8.47h1.53c.43,0,.77-.11.99-.36.22-.23.34-.53.34-.9s-.11-.68-.34-.94c-.22-.24-.54-.36-.99-.36h-1.53v2.56Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M17.97,20.44h-7.26c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.26c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M17.97,24.51h-7.26c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.26c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M14.34,41.58c-.53,0-.96-.43-.96-.96V14.38c0-.53.43-.96.96-.96s.96.43.96.96v26.24c0,.53-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M17.97,28.57h-7.26c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.26c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M17.97,32.64h-7.26c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.26c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M17.97,36.68h-7.26c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.26c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M14.34,56.26c-1.75,0-3.49-.77-4.65-2.07-1.03-1.15-1.51-2.58-1.34-4.04l1.12-9.64c.06-.48.47-.85.96-.85h7.8c.49,0,.9.37.96.85l1.12,9.64c.17,1.46-.31,2.89-1.34,4.05-1.16,1.3-2.89,2.07-4.63,2.07ZM11.29,41.58l-1.02,8.79c-.1.9.2,1.81.86,2.54.8.9,2,1.43,3.22,1.43s2.39-.53,3.19-1.43c.66-.74.97-1.64.86-2.55l-1.02-8.79h-6.09Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M14.34,53.19c-.99,0-1.96-.44-2.6-1.19-.55-.65-.79-1.44-.66-2.24l.28-1.7c.08-.46.48-.8.95-.8h4.02c.47,0,.87.34.95.8l.28,1.69c.13.8-.1,1.6-.66,2.24-.64.74-1.6,1.19-2.58,1.19ZM13.14,49.18l-.15.89c-.04.24.04.47.22.68.27.31.71.51,1.13.51s.84-.2,1.11-.51c.13-.15.27-.39.22-.69l-.15-.89h-2.39Z"
      />
    </svg>
  );
}

export function ApkIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-3"
        fill="#00b3e8"
        d="M30.35,61H.96c-.53,0-.96-.43-.96-.96V.96C0,.43.43,0,.96,0h44.07c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.52,14.6c-.18.18-.43.28-.69.28ZM1.93,59.08h28.02l13.96-14.04.16-43.12H1.93v57.16Z"
      />
      <path
        class="cls-3"
        fill="#00b3e8"
        d="M30.35,61c-.12,0-.25-.02-.37-.07-.36-.15-.6-.5-.6-.89v-7.28c0-5.73,2.54-8.28,8.24-8.28h7.24c.39,0,.74.23.89.59.15.36.07.77-.21,1.04l-14.52,14.6c-.18.19-.43.28-.69.28ZM37.63,46.4c-4.6,0-6.32,1.72-6.32,6.36v4.95l11.25-11.31h-4.93Z"
      />
      <rect
        class="cls-3"
        fill="#00b3e8"
        x="1.06"
        y=".96"
        width="43.88"
        height="14.77"
      />
      <path
        class="cls-2"
        fill="#fefefe"
        d="M12.72,12.55h-1.67v-1.49h1.67v1.49ZM18.54,10.86h-2.73l-.52,1.69h-1.57l2.68-7.85h1.59l2.68,7.85h-1.57l-.54-1.69ZM16.2,9.65h1.97l-.97-3.11h-.02l-.97,3.11ZM22.93,9.71v2.84h-1.55v-7.85h3.11c.9,0,1.59.25,2.12.7.5.45.77,1.05.77,1.81s-.26,1.35-.77,1.81c-.53.47-1.22.7-2.12.7h-1.55ZM22.93,8.49h1.55c.43,0,.77-.11.99-.36.22-.23.34-.55.34-.9,0-.38-.11-.7-.34-.94-.23-.24-.56-.36-.99-.36h-1.55v2.56ZM30.94,9.28h-.86v3.27h-1.57v-7.85h1.57v3.22h.67l2.06-3.22h1.93l-2.64,3.67,2.84,4.18h-1.91l-2.1-3.27Z"
      />
      <path
        class="cls-1"
        fill="#00b3e8"
        d="M20.58,33.74l2.96-2.37c.34-.26.39-.75.13-1.09-.26-.36-.75-.41-1.09-.13l-3.14,2.48c-.97-.85-2.11-1.54-3.41-2.03-1.27-.47-2.58-.7-3.89-.73l-.71-3.93c-.08-.43-.49-.71-.9-.64-.43.07-.71.49-.64.9l.67,3.74c-4.19.51-7.99,3.27-9.58,7.53l-.02.04v.47c.08.22.22.41.47.49l20.42,7.73c.39.15.84-.04,1.01-.45,1.57-4.25.54-8.84-2.28-12h0ZM2.74,37.29c2.21-4.78,7.77-7.11,12.74-5.23,4.98,1.9,7.62,7.34,6.16,12.38l-18.9-7.15Z"
      />
      <path
        class="cls-1"
        fill="#00b3e8"
        d="M21.57,46.93L1.15,39.19c-.06-.02-.13-.04-.19-.04v2.79l.37-1,18.96,7.19-4.47,11.91h1.67l4.53-12.1c.15-.4-.04-.85-.45-1.02h0Z"
      />
      <path
        class="cls-1"
        fill="#00b3e8"
        d="M29.17,39.27c-1.4-.53-2.98.19-3.5,1.6l-3.86,10.25c-.52,1.43.19,3.01,1.59,3.54,1.4.55,2.99-.17,3.52-1.6l3.84-10.25c.54-1.41-.17-2.99-1.59-3.54h0ZM25.46,52.51c-.22.6-.9.92-1.5.68-.62-.22-.92-.9-.69-1.5l3.86-10.25c.22-.62.9-.92,1.5-.7.6.23.92.9.67,1.52l-3.84,10.25Z"
      />
      <path
        class="cls-1"
        fill="#00b3e8"
        d="M10.41,33.92c.58.23.86.87.66,1.43-.22.58-.86.87-1.42.66-.58-.23-.86-.87-.65-1.43.22-.58.86-.87,1.42-.66h0Z"
      />
      <path
        class="cls-1"
        fill="#00b3e8"
        d="M18.07,36.82c.56.23.86.87.64,1.43-.21.58-.84.87-1.42.66-.56-.23-.86-.87-.64-1.43.21-.58.84-.87,1.42-.66h0Z"
      />
    </svg>
  );
}

export function PptxIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 45.94 61.18"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.28,61.09H1.03c-.53,0-.96-.43-.96-.96V1.05C.07.52.5.09,1.03.09h43.88c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.46,14.61c-.18.18-.43.29-.68.29ZM1.99,59.17h27.9l13.9-14.04.16-43.12H1.99v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.28,61.09c-.12,0-.25-.02-.37-.07-.36-.15-.59-.5-.59-.89v-7.26c0-5.74,2.53-8.3,8.21-8.3h7.21c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.46,14.61c-.18.19-.43.29-.68.29ZM37.53,46.48c-4.58,0-6.29,1.73-6.29,6.38v4.93l11.2-11.31h-4.91Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.12"
        y="1.05"
        width="43.7"
        height="14.76"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M9.69,12.63h-1.66v-1.49h1.66v1.49ZM12.88,9.78v2.84h-1.55v-7.83h3.09c.89,0,1.58.23,2.11.68.5.47.76,1.07.76,1.81s-.26,1.36-.76,1.81c-.52.47-1.21.7-2.11.7h-1.55ZM12.88,8.56h1.55c.43,0,.76-.11.99-.36.22-.23.33-.53.33-.9s-.11-.68-.33-.94c-.22-.24-.56-.36-.99-.36h-1.55v2.56ZM20,9.78v2.84h-1.57v-7.83h3.09c.89,0,1.6.23,2.11.68.5.47.76,1.07.76,1.81s-.26,1.36-.76,1.81c-.5.47-1.21.7-2.11.7h-1.53ZM20,8.56h1.53c.43,0,.76-.11.99-.36.22-.23.33-.53.33-.9s-.11-.68-.33-.94c-.22-.24-.54-.36-.99-.36h-1.53v2.56ZM30.9,6h-2.2v6.62h-1.55v-6.62h-2.18v-1.2h5.92v1.2ZM34.5,7.56l1.45-2.77h1.83l-2.31,3.88,2.44,3.95h-1.88l-1.49-2.82-1.51,2.82h-1.81l2.35-3.95-2.29-3.88h1.81l1.42,2.77Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.4,45.94c-.34,0-.66-.18-.84-.49l-3.34-5.93c-.26-.46-.1-1.05.37-1.31.46-.26,1.05-.1,1.31.37l3.34,5.93c.26.46.1,1.05-.37,1.31-.15.08-.31.12-.47.12ZM15.54,45.94c-.16,0-.32-.04-.47-.12-.46-.26-.63-.85-.37-1.31l3.34-5.93c.26-.46.85-.63,1.31-.37.46.26.63.85.37,1.31l-3.34,5.93c-.18.31-.5.49-.84.49Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M22.98,23.09c-.53,0-.96-.43-.96-.96v-2.05c0-.53.43-.96.96-.96s.96.43.96.96v2.05c0,.53-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M38.47,40.01H7.48c-.53,0-.96-.43-.96-.96v-16.92c0-.53.43-.96.96-.96h30.99c.53,0,.96.43.96.96v16.92c0,.53-.43.96-.96.96ZM8.44,38.09h29.07v-15H8.44v15Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M34.4,35.72c-.53,0-.96-.43-.96-.96v-8.19c0-.53.43-.96.96-.96s.96.43.96.96v8.19c0,.53-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M31.33,35.72c-.53,0-.96-.43-.96-.96l-.02-4.82c0-.53.43-.96.96-.96.52.02.96.43.96.96l.02,4.82c0,.53-.43.96-.96.96h0Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M28.24,35.72c-.53,0-.96-.43-.96-.96v-2.41c0-.53.43-.96.96-.96s.96.43.96.96v2.41c0,.53-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M20.91,27.53h-9.43c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h9.43c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M17.24,31.62h-5.76c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h5.76c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M11.48,35.72c-.53,0-.96-.43-.96-.96,0-.53.43-.96.96-.96l11.65-.02h0c.53,0,.96.43.96.96,0,.53-.43.96-.96.96l-11.65.02h0Z"
      />
    </svg>
  );
}

export function RarIcon() {
  return (
    <svg
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="45px"
      height="50px"
      viewBox="0 0 46 61"
    >
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.31,61H1.06c-.53,0-.96-.43-.96-.96V.96C.1.43.53,0,1.06,0h43.88c.26,0,.5.1.68.28.18.18.28.43.28.68l-.17,44.48c0,.25-.1.49-.28.67l-14.46,14.61c-.18.18-.43.29-.68.29ZM2.02,59.08h27.9l13.9-14.04.16-43.12H2.02v57.16Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M30.31,61c-.12,0-.25-.02-.37-.07-.36-.15-.59-.5-.59-.89v-7.26c0-5.74,2.53-8.3,8.21-8.3h7.21c.39,0,.74.23.89.59.15.36.07.77-.2,1.05l-14.46,14.61c-.18.19-.43.29-.68.29ZM37.56,46.39c-4.58,0-6.29,1.73-6.29,6.38v4.93l11.2-11.31h-4.91Z"
      />
      <rect
        class="cls-2"
        fill="#00b3e8"
        x="1.15"
        y=".96"
        width="43.7"
        height="14.78"
      />
      <path
        class="cls-1"
        fill="#fefefe"
        d="M12.82,12.53h-1.66v-1.47h1.66v1.47ZM16,9.41v3.12h-1.55v-7.83h2.93c.88,0,1.57.21,2.07.6.5.41.75.98.75,1.71,0,.39-.09.75-.32,1.04-.21.3-.5.53-.91.72.47.13.78.36.99.68.2.32.3.72.3,1.19v.56c0,.23.04.45.09.7.06.23.15.41.3.53v.11h-1.6c-.15-.11-.24-.3-.28-.58-.04-.26-.06-.51-.06-.75v-.56c0-.38-.11-.7-.32-.9-.22-.23-.52-.32-.89-.32h-1.49ZM16,8.19h1.34c.45,0,.76-.09.99-.28.2-.17.32-.45.32-.83,0-.36-.11-.64-.32-.85-.22-.21-.54-.32-.95-.32h-1.38v2.28ZM25.82,10.84h-2.72l-.52,1.69h-1.57l2.66-7.83h1.58l2.66,7.83h-1.57l-.54-1.69ZM23.49,9.64h1.96l-.97-3.11h-.02l-.97,3.11ZM30.2,9.41v3.12h-1.55v-7.83h2.93c.88,0,1.57.21,2.07.6.5.41.74.98.74,1.71,0,.39-.09.75-.32,1.04-.2.3-.5.53-.91.72.46.13.78.36.99.68.21.32.3.72.3,1.19v.56c0,.23.04.45.09.7.06.23.15.41.3.53v.11h-1.6c-.15-.11-.24-.3-.28-.58-.04-.26-.06-.51-.06-.75v-.56c0-.38-.11-.7-.32-.9-.22-.23-.52-.32-.89-.32h-1.49ZM30.2,8.19h1.34c.45,0,.76-.09.99-.28.2-.17.32-.45.32-.83,0-.36-.11-.64-.32-.85-.22-.21-.54-.32-.95-.32h-1.38v2.28Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M18,20.44h-7.23c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.23c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M18,24.51h-7.23c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.23c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M14.38,41.58c-.53,0-.96-.43-.96-.96V14.38c0-.53.43-.96.96-.96s.96.43.96.96v26.24c0,.53-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M18,28.57h-7.23c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.23c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M18,32.64h-7.23c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.23c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M18,36.7h-7.23c-.53,0-.96-.43-.96-.96s.43-.96.96-.96h7.23c.53,0,.96.43.96.96s-.43.96-.96.96Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M14.38,56.27c-1.74,0-3.47-.78-4.63-2.07-1.03-1.16-1.51-2.6-1.34-4.06l1.12-9.64c.06-.48.47-.85.95-.85h7.77c.49,0,.9.36.95.85l1.12,9.64c.17,1.46-.31,2.9-1.34,4.06-1.16,1.3-2.88,2.07-4.6,2.07ZM11.34,41.58l-1.02,8.79c-.1.91.2,1.82.87,2.56.8.9,1.99,1.43,3.19,1.43s2.37-.53,3.17-1.43c.66-.74.97-1.66.87-2.56l-1.02-8.79h-6.06Z"
      />
      <path
        class="cls-2"
        fill="#00b3e8"
        d="M14.38,53.19c-.99,0-1.96-.45-2.6-1.19-.55-.64-.77-1.43-.64-2.22l.28-1.71c.08-.46.48-.8.95-.8h4.01c.47,0,.87.34.95.8l.28,1.71c.13.79-.1,1.57-.65,2.22-.63.75-1.6,1.19-2.57,1.19ZM13.18,49.18l-.15.91c-.05.28.09.52.21.66.27.31.71.52,1.13.52s.85-.2,1.11-.51c.12-.15.26-.38.22-.67l-.15-.91h-2.38Z"
      />
    </svg>
  );
}
